import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

function App() {
  const location = useLocation();

  useEffect(() => {
    const siteId = sessionStorage.getItem('siteid');
    if (siteId == 1) {
      ReactGA.initialize('G-7QQN41BM9S');
    } else if (siteId == 3) {
      ReactGA.initialize('G-S01Q2JR30B');
    } else {
    }

    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
