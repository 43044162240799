import { useState } from 'react';

export default function PreviewImage({ file }) {
  const [preview, setPreview] = useState({});
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }
  return (
    <div className="avatar-title bg-light rounded">
      <img src={preview} alt="" className="h-auto" />
    </div>
  );
}
