import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import withRouter from '../../Components/Common/withRouter';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// actions
import {
  loginUser,
  resetLoginFlag,
  getDomains as onGetDomains,
} from '../../slices/thunks';

import logoLight from '../../assets/images/logo-light.png';
//import images

const Login = (props) => {
  const dispatch = useDispatch();

  const [userLogin, setUserLogin] = useState([]);
  const [domain, setDomain] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userid: userLogin.userid || '',
      password: userLogin.password || '',
      siteid: (domain && domain.siteid) || '',
    },
    validationSchema: Yup.object({
      userid: Yup.string().required('Please Enter Your Username'),
      password: Yup.string().required('Please Enter Your Password'),
      siteid: Yup.string().required('Select a domain to proceed'),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const { error, errorFlag, loading, domains, isDomainSuccess } = useSelector(
    (state) => ({
      error: state.Login.error,
      loading: state.Login.loading,
      errorFlag: state.Login.errorFlag,
      domains: state.Domains.domains,
      isDomainSuccess: state.Domains.isDomainSuccess,
    })
  );

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    setDomain(domains);
  }, [domains]);

  useEffect(() => {
    if (errorFlag) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorFlag]);

  document.title = 'SignIn';
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="75" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">Admin Dashboard</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to broadband.asia
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="userid" className="form-label">
                            Username
                          </Label>
                          <Input
                            id="userid"
                            name="userid"
                            className="form-control"
                            placeholder="Enter Username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.userid || ''}
                            invalid={
                              validation.touched.userid &&
                              validation.errors.userid
                                ? true
                                : false
                            }
                          />
                          {validation.touched.userid &&
                          validation.errors.userid ? (
                            <FormFeedback type="invalid">
                              {validation.errors.userid}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              id="password-input"
                              name="password"
                              value={validation.values.password || ''}
                              type={showPassword ? 'text' : 'password'}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                              onClick={() => setShowPassword(!showPassword)}
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="plan-siteid-input"
                          >
                            Select Domain*
                          </Label>
                          <Input
                            name="siteid"
                            type="select"
                            className="form-select"
                            id="plan-siteid-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.siteid || ''}
                            invalid={
                              validation.touched.siteid &&
                              validation.errors.siteid
                                ? true
                                : false
                            }
                          >
                            <option value="">Select</option>
                            {domain.map((item, key) => (
                              <React.Fragment key={key}>
                                <option value={item.id} key={item.id}>
                                  {item.domainName}
                                </option>
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.errors.siteid &&
                          validation.touched.siteid ? (
                            <FormFeedback type="invalid">
                              {validation.errors.siteid}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={error ? null : loading ? true : false}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                Loading...
                              </Spinner>
                            ) : null}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
