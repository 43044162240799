import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { getLeads as onGetLeads } from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';
import { Published } from '../../Components/Common/Utils';

const Leads = () => {
  const dispatch = useDispatch();

  const { leads, isLeadSuccess, error } = useSelector((state) => ({
    leads: state.Leads.leads,
    isLeadSuccess: state.Leads.isLeadSuccess,
    error: state.Leads.error,
  }));

  const [lead, setLead] = useState([]);

  useEffect(() => {
    if (leads && !isLeadSuccess) {
      dispatch(onGetLeads());
    }
  }, [dispatch, leads]);

  useEffect(() => {
    setLead(leads);
  }, [leads]);

  useEffect(() => {
    if (!isEmpty(leads)) {
      setLead(leads);
    }
  }, [leads]);

  // Leads Column
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: false,
      },
      {
        Header: 'Published',
        accessor: 'dateTime',
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterable: false,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        filterable: false,
      },
      {
        Header: 'City',
        accessor: 'city',
        filterable: false,
      },
      {
        Header: 'Zip',
        accessor: 'zip',
        filterable: false,
      },
      {
        Header: 'New Connection',
        accessor: 'newConnection',
        Cell: (cell) => {
          switch (cell.value) {
            case 1:
              return (
                <span className="badge text-uppercase badge-soft-success">
                  Yes
                </span>
              );
            case 0:
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  No
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {cell.value}
                </span>
              );
          }
        },
      },
      {
        Header: 'Manage Plan',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit Plan">
                <Link
                  to={'/plans/edit/' + cellProps.row.original.formName}
                  className="text-muted d-inline-block edit-item-btn"
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                  Edit Plan
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Plans Abuses Reported | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={leads}
        />

        <Container fluid>
          <BreadCrumb title="Report Abuses" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="leadList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Manage Leads</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-info ms-1"
                          onClick={() => setIsExportCSV(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isLeadSuccess ? (
                      <>
                        {leads.length ? (
                          <TableContainer
                            columns={columns}
                            data={leads || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            className="custom-header-css"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for lead..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Leads;
