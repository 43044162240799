import { createSlice } from '@reduxjs/toolkit';
import { getProviders, addNewProvider, updateProvider } from './thunk';
export const initialState = {
  providers: [],
  error: {},
};

const ProvidersSlice = createSlice({
  name: 'ProvidersSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getProviders.fulfilled, (state, action) => {
      state.providers = action.payload.payload;
      state.isProviderCreated = false;
      state.isProviderSuccess = true;
    });

    builder.addCase(getProviders.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isProviderCreated = false;
      state.isProviderSuccess = false;
    });

    builder.addCase(addNewProvider.fulfilled, (state, action) => {
      state.providers.push(action.payload.payload);
      state.isProviderCreated = true;
    });
    builder.addCase(addNewProvider.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateProvider.fulfilled, (state, action) => {
      state.providers = state.providers.map((provider) =>
        provider.id.toString() === action.payload.payload.id.toString()
          ? { ...provider, ...action.payload.payload }
          : provider
      );
    });
    builder.addCase(updateProvider.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default ProvidersSlice.reducer;
