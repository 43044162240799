import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';

import Select from 'react-select';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewProvider as onAddNewProvider,
  updateProvider as onUpdateProvider,
  getProviders as onGetProviders,
  getCities as onGetCities,
} from '../../slices/thunks';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { useNavigate, useParams } from 'react-router-dom';

//formik
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';

// status
import StatusMenu from '../../Data/status.json';
import PreviewImage from '../../Components/Common/PreviewImage';
import axios from 'axios';
import { values } from 'lodash';

const AddProvider = () => {

  
  const { providerid } = useParams();
  document.title = 'Providers | Admin & Dashboard';

  const history = useNavigate();
  const dispatch = useDispatch();

  const { providers, cities, isCitySuccess,  isProviderSuccess } = useSelector((state) => ({
    providers: state.Providers.providers,
    cities: state.Cities.cities,
    isCitySuccess: state.Cities.isCitySuccess,
    isProviderSuccess: state.Providers.isProviderSuccess,
  }));

  const [provider, setProvider] = useState([]);
  const [city, setCity] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const uploadToCloudinary = async (file) => {
    const img_file = file,
      formData = new FormData();
    formData.append('file', img_file);
    formData.append('upload_preset', 'broadband');
    const uninterceptedAxiosInstance = axios.create();
    var res = await uninterceptedAxiosInstance.post(
      'https://api.cloudinary.com/v1_1/dxzr7l0pe/image/upload',
      formData
    );
    return res.data.secure_url;
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (provider && provider.name) || '',
      shortName: (provider && provider.shortName) || '',
      logo: (provider && provider.logo) || '',
      image: (provider && provider.image) || '',
      description: (provider && provider.description) || '',
      field1: (provider && provider.field1) || '',
      provider_top_banner_plans: (provider && provider.provider_top_banner_plans) || '',
      provider_top_banner_txt: (provider && provider.provider_top_banner_txt) || '',
      provider_top_banner_color: (provider && provider.provider_top_banner_color) || '',
      field3: (provider && provider.field3) || '',
      status: (provider && provider.status) || '',
      link: (provider && provider.link) || '',
      sortingOrder: (provider && provider.sortingOrder) || '',
      rating: (provider && provider.rating) || '',
      meta_title: (provider && provider.meta_title) || '',
      meta_keyword: (provider && provider.meta_keyword) || '',
      meta_description: (provider && provider.meta_description) || '',
      citiesMapping: (provider && provider.citiesMapping) || [],
      seoName: (provider && provider.seoName) || [],
      faq: (provider && provider.faq) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a provider name'),
      status: Yup.string().required('Please select provider status'),
      rating: Yup.string().required('Please enter rating'),
      sortingOrder: Yup.string().required('Please enter sorting order'),
      seoName: Yup.string().required('Please enter seo name for the plan'),
      faq: Yup.string().required('Please enter faq'),
      meta_title: Yup.string()
        .max(
          100,
          'Max 100 characters allowed, though its best to have maximum 70 charaters in title'
        )
        .required('Please enter a meta title'),
      meta_keyword: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, keywords must not have more than 30 words'
        )
        .required('Please enter a meta keyword'),
      meta_description: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, though it best to have not more than 155 characters in description'
        )
        .required('Please enter a meta description'),
    }),
    onSubmit: async (values) => {
      let logoUrl = values.logo,
        imageUrl = values.image;

      if (
        typeof logoUrl?.name != 'undefined' &&
        typeof logoUrl?.name == 'string'
      ) {
        logoUrl = await uploadToCloudinary(logoUrl);
      }

      if (
        typeof imageUrl?.name != 'undefined' &&
        typeof imageUrl?.name == 'string'
      ) {
        imageUrl = await uploadToCloudinary(imageUrl);
      }

      if (isEdit) {
        const updateProvider = {
          id: provider ? provider.id : providerid,
          name: values.name,
          shortName: values.shortName,
          logo: logoUrl,
          image: imageUrl,
          description: values.description,
          field1: values.field1,
          provider_top_banner_plans: values.provider_top_banner_plans,
          provider_top_banner_txt: values.provider_top_banner_txt,
          provider_top_banner_color: values.provider_top_banner_color,
          field3: values.field3,
          status: values.status,
          link: values.link,
          sortingOrder: values.sortingOrder,
          rating: values.rating,
          meta_title: values.meta_title,
          meta_keyword: values.meta_keyword,
          meta_description: values.meta_description,
          citiesMapping: values.citiesMapping,
          seoName: values.seoName,
          faq: values.faq,
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // update Provider
        dispatch(onUpdateProvider(updateProvider));
        history('/providers');
        validation.resetForm();
      } else {
        const newProvider = {
          name: values['name'],
          shortName: values['shortName'],
          logo: logoUrl,
          image: imageUrl,
          description: values['description'],
          field1: values['field1'],
          provider_top_banner_plans: values['provider_top_banner_plans'],
          provider_top_banner_txt: values['provider_top_banner_txt'],
          provider_top_banner_color: values['provider_top_banner_color'],
          field3: values['field3'],
          status: values['status'],
          link: values['link'],
          sortingOrder: values['sortingOrder'],
          rating: values['rating'],
          meta_title: values['meta_title'],
          meta_keyword: values['meta_keyword'],
          meta_description: values['meta_description'],
          citiesMapping: values['citiesMapping'],
          seoName: values['seoName'],
          faq: values['faq'],
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };

        // save new provider
        dispatch(onAddNewProvider(newProvider));
        history('/providers');
        validation.resetForm();
      }
    },
  });

  const editorHandler = (event, editor) => {
    validation.setFieldValue('description', editor.getData());
  };
  const editorHandler2 = (event, editor) => {
    validation.setFieldValue('field1', editor.getData());
  };
  const editorHandler3 = (event, editor) => {
    validation.setFieldValue('provider_top_banner_plans', editor.getData());
  };
  const editorHandler4 = (event, editor) => {
    validation.setFieldValue('provider_top_banner_txt', editor.getData());
  };
  const editorHandler5 = (event, editor) => {
    validation.setFieldValue('faq', editor.getData());
  };
  const editorHandler6 = (event, editor) => {
    validation.setFieldValue('field3', editor.getData());
  };
  const imageHandler = (event) => {
    validation.setFieldValue('image', event.target.files[0]);
  };
  const logoHandler = (event) => {
    validation.setFieldValue('logo', event.target.files[0]);
  };

  useEffect(() => {
    if (providers && !isProviderSuccess) {
      dispatch(onGetProviders());
    }
  }, [dispatch, providers]);

  useEffect(() => {
    if (cities && !isCitySuccess) {
      dispatch(onGetCities());
    }
  }, [dispatch, cities]);

  useEffect(() => {
    setCity(cities);
  }, [cities]);

  useEffect(() => {
    setProvider(providers);
  }, [providers]);

  useEffect(() => {
    if (typeof providerid != 'undefined' && !!providerid) {
      const fetchedElem = providers.find((elem) => elem.id == providerid);
      if (typeof fetchedElem != 'undefined') {
        setProvider({
          id: fetchedElem.id,
          name: fetchedElem.name || '',
          shortName: fetchedElem.shortName || '',
          logo: fetchedElem.logo || '',
          image: fetchedElem.image || '',
          description: fetchedElem.description || '',
          field1: fetchedElem.field1 || '',
          provider_top_banner_plans: fetchedElem.provider_top_banner_plans || '',
          provider_top_banner_txt: fetchedElem.provider_top_banner_txt || '',
          provider_top_banner_color: fetchedElem.provider_top_banner_color || '',
          field3: fetchedElem.field3 || '',
          status: fetchedElem.status.toString() || '',
          sortingOrder: fetchedElem.sortingOrder || '',
          link: fetchedElem.link || '',
          rating: fetchedElem.rating || '',
          meta_title: fetchedElem.meta_title || '',
          meta_keyword: fetchedElem.meta_keyword || '',
          meta_description: fetchedElem.meta_description || '',
          citiesMapping: fetchedElem.citiesMapping || [],
          seoName: fetchedElem.seoName || [],
          faq: fetchedElem.faq || '',
        });
        setIsEdit(true);
      } else {
        history('/providers');
      }
    }
  }, [providerid]);
  
  const [selectedColor, setSelectedColor] = useState(validation.values.provider_top_banner_color || '');
  
  console.log("City ======>", city);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={!!isEdit ? 'Update Provider' : 'Add Provider'}
          pageTitle="Dashboard"
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-8">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="provider-title-input"
                        >
                          Provider Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="provider-title-input"
                          placeholder="Enter provider title"
                          name="name"
                          value={validation.values.name || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.name && validation.touched.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && validation.touched.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="provider-shortname-input"
                        >
                          Provider Shortname
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="provider-shortname-input"
                          placeholder="Enter provider short name"
                          name="shortName"
                          value={validation.values.shortName || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.shortName &&
                            validation.touched.shortName
                              ? true
                              : false
                          }
                        />
                        {validation.errors.shortName &&
                        validation.touched.shortName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.shortName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                  <Row style={{ marginBottom: 30 }}>
                    <Col>
                      <Label>Provider Banner Details</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.provider_top_banner_txt || ''}
                        onChange={editorHandler4}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 30 }}>
                    <Col>
                      <Label>Provider Banner Plans</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.provider_top_banner_plans || ''}
                        onChange={editorHandler3}
                      />
                    </Col>
                  </Row>
                  <Row>
                  <div className="col-4">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="provider-colorname-input"
                        >
                          Provider Banner Color
                        </Label>
                        <Input
                          type="color"
                          className="form-control colorInput"
                          style={{ width: '45px', padding: '5px' }}
                          id="provider-colorname-input"
                          placeholder="Select Provider Banner Color"
                          name="provider_top_banner_color"
                          value={validation.values.provider_top_banner_color}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.provider_top_banner_color &&
                            validation.touched.provider_top_banner_color
                              ? true
                              : false
                          }
                        />
                        {validation.errors.provider_top_banner_color &&
                        validation.touched.provider_top_banner_color ? (
                          <FormFeedback type="invalid">
                              {validation.errors.provider_top_banner_color}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                  <Row style={{ marginBottom: 30 }}>
                    <Col>
                      <Label>Provider Feature</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.field3 || ''}
                        onChange={editorHandler6}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 30 }}>
                    <Col>
                      <Label>Provider Description</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.description || ''}
                        onChange={editorHandler}
                      />
                    </Col>
                  </Row>
                  {/* Provider Top Section Content */}
                  <Row>
                    <Col>
                      <Label>Provider Top Section Content</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.field1 || ''}
                        onChange={editorHandler2}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 30 }}>
                    <Col>
                      <Label>Provider Faq</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.faq || ''}
                        onChange={editorHandler5}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Provider Status</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="mb-3">
                      <Label htmlFor="status-field" className="form-label">
                        Status
                      </Label>

                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        id="status-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ''}
                        invalid={
                          validation.errors.status && validation.touched.status
                            ? true
                            : false
                        }
                      >
                        {StatusMenu.map((item, key) => (
                          <React.Fragment key={key}>
                            {item.options.map((item, key) => (
                              <option value={item.value} key={key}>
                                {item.label}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label htmlFor="link-field" className="form-label">
                        Provider Affliate Link
                      </Label>

                      <Input
                        name="link"
                        type="text"
                        className="form-control"
                        id="link-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.link || ''}
                        invalid={
                          validation.errors.link && validation.touched.link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link && validation.errors.link ? (
                        <FormFeedback type="invalid">
                          {validation.errors.link}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label
                        htmlFor="sortingOrder-field"
                        className="form-label"
                      >
                        Sorting Order
                      </Label>

                      <Input
                        name="sortingOrder"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="sortingOrder-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sortingOrder || ''}
                        invalid={
                          validation.errors.sortingOrder &&
                          validation.touched.sortingOrder
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sortingOrder &&
                      validation.errors.sortingOrder ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sortingOrder}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label htmlFor="rating-field" className="form-label">
                        Provider Rating
                      </Label>

                      <Input
                        name="rating"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="rating-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rating || ''}
                        invalid={
                          validation.errors.rating && validation.touched.rating
                            ? true
                            : false
                        }
                      />
                      {validation.touched.rating && validation.errors.rating ? (
                        <FormFeedback type="invalid">
                          {validation.errors.rating}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Gallery</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="mb-4">
                      <h5 className="fs-15 mb-1">Provider Page Logo</h5>
                      <p className="text-muted">
                        280 X 160 Image with Solid Background
                      </p>
                      <div>
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="product-image-input"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Select Image"
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="product-image-input"
                              type="file"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={imageHandler}
                            />
                          </div>
                          <div className="plan-main-img">
                            {validation.values.image &&
                            validation.values.image.name ? (
                              <PreviewImage file={validation.values.image} />
                            ) : (
                              <div className="avatar-title bg-light rounded">
                                <img
                                  src={validation.values.image || ''}
                                  alt=""
                                  className="h-auto"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {validation.errors.image && validation.touched.image ? (
                        <FormFeedback type="invalid">
                          {validation.errors.image}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="mb-4">
                      <h5 className="fs-15 mb-1">Broadband Plan Logo</h5>
                      <p className="text-muted">100 X 100 Transparent Image</p>
                      <div>
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="product-logo-input"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Select Image"
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="product-logo-input"
                              type="file"
                              accept="image/png"
                              onChange={logoHandler}
                            />
                          </div>
                          <div className="plan-img">
                            {validation.values.logo &&
                            validation.values.logo.name ? (
                              <PreviewImage file={validation.values.logo} />
                            ) : (
                              <div className="avatar-title bg-light rounded">
                                <img
                                  src={validation.values.logo || ''}
                                  alt=""
                                  className="h-auto"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {validation.errors.logo ? (
                        <FormFeedback type="invalid">
                          {validation.errors.logo}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button>
              </div>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">City Mapping</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    {city && city.length > 0 ? (
                      <div className="mb-3">
                        <Label className="form-label">Select Cities</Label>

                        <Select
                          name="citiesMapping"
                          value={validation.values.citiesMapping}
                          isMulti={true}
                          isClearable={true}
                          onChange={(selectedOptions) =>
                            validation.setFieldValue(
                              'citiesMapping',
                              selectedOptions
                            )
                          }
                          options={city.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </CardBody>
              </Card>
              {/* Card of Seo Name */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">SEO Name</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                  <Label
                          className="form-label"
                          htmlFor="provider-seoname-input"
                        >
                          Provider SEO Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="provider-seoname-input"
                          placeholder="Enter seo name"
                          name="seoName"
                          value={validation.values.seoName || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.seoName &&
                            validation.touched.seoName
                              ? true
                              : false
                          }
                        />
                        {validation.errors.seoName &&
                        validation.touched.seoName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.seoName}
                          </FormFeedback>
                        ) : null}
                  </div>
                </CardBody>
              </Card>
              {/* Card of Seo Name */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Meta Data</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="meta-title-input">
                      Meta title
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter meta title"
                      id="meta-title-input"
                      name="meta_title"
                      value={validation.values.meta_title || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_title &&
                        validation.touched.meta_title
                          ? true
                          : false
                      }
                    />
                    {validation.errors.meta_title &&
                    validation.touched.meta_title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_title}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="meta-keywords-input">
                      Meta Keywords
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter meta keywords"
                      id="meta-keywords-input"
                      name="meta_keyword"
                      value={validation.values.meta_keyword || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_keyword &&
                        validation.touched.meta_keyword
                          ? true
                          : false
                      }
                    />
                    {validation.errors.meta_keyword &&
                    validation.touched.meta_keyword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_keyword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label
                      className="form-label"
                      htmlFor="meta-description-input"
                    >
                      Meta Description
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="meta-description-input"
                      placeholder="Enter meta description"
                      name="meta_description"
                      rows="3"
                      value={validation.values.meta_description || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_description &&
                        validation.touched.meta_description
                          ? true
                          : false
                      }
                    ></Input>
                    {validation.errors.meta_description &&
                    validation.touched.meta_description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddProvider;
