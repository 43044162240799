import { createSlice } from '@reduxjs/toolkit';
import { getCountries, addNewCountry, updateCountry } from './thunk';
export const initialState = {
  countries: [],
  error: {},
};

const CountriesSlice = createSlice({
  name: 'CountriesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.payload;
      state.isCountryCreated = false;
      state.isCountrySuccess = true;
    });

    builder.addCase(getCountries.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isCountryCreated = false;
      state.isCountrySuccess = false;
    });

    builder.addCase(addNewCountry.fulfilled, (state, action) => {
      state.countries.push(action.payload.payload);
      state.isCountryCreated = true;
    });
    builder.addCase(addNewCountry.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateCountry.fulfilled, (state, action) => {
      state.countries = state.countries.map((country) =>
        country.id.toString() === action.payload.payload.id.toString()
          ? { ...country, ...action.payload.payload }
          : country
      );
    });
    builder.addCase(updateCountry.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default CountriesSlice.reducer;
