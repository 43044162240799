import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import {
    updateCity as onUpdateCity,
    addNewCity as onAddNewCity,
    getCities as onGetCities,
} from '../../slices/thunks';

// status
import StatusMenu from "../../Data/status.json";
import axios from "axios";

import BreadCrumb from '../../Components/Common/BreadCrumb';

const AddCity = () => {

    const { cityid } = useParams();
    document.title = 'City | Admin & Dashboard';

    let content = "";
    const history = useNavigate();
    const dispatch = useDispatch();
  

    const {
        cities,
    } = useSelector((state) => ({
        cities: state.Cities.cities,
    }));

    const [isEdit, setIsEdit] = useState(false); 
    const [currentCity, setCurrentCity] = useState([]); 

    const validation = useFormik({
        enableReinitialize: true,
    
        initialValues: {
            name: (currentCity && currentCity.name) || '',
            status: (currentCity && currentCity.status) || '',
            field1:
            (currentCity && currentCity.field1) || '',
            field2:
            (currentCity && currentCity.field2) || '',
            meta_title: (currentCity && currentCity.meta_title) || '',
            meta_keyword: (currentCity && currentCity.meta_keyword) || '',
            meta_description: (currentCity && currentCity.meta_description) || '',
            seoName: (currentCity && currentCity.seoName) || [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter a plan name'),
            status: Yup.string().required('Please select status'),
            seoName: Yup.string().required('Please enter seo name for the plan'),
            meta_title: Yup.string()
            .max(
                100,
                'Max 100 characters allowed, though its best to have maximum 70 charaters in title'
            )
            .required('Please enter a meta title'),
            meta_keyword: Yup.string()
            .max(
                500,
                'Max 500 characters allowed, keywords must not have more than 30 words'
            )
            .required('Please enter a meta keyword'),
            meta_description: Yup.string()
            .max(
                500,
                'Max 500 characters allowed, though it best to have not more than 155 characters in description'
            )
            .required('Please enter a meta description'),
        }),
        onSubmit: (values) => {
          if (isEdit) {
            const updateCity = {
                id: currentCity ? currentCity.id : cityid,
                name: values.name,
                status: values.status,
                projectId: 3,
                field1: values.field1,
                field2: values.field2,
                siteId: sessionStorage.getItem('siteid'),
                meta_title: values.meta_title,
                meta_keyword: values.meta_keyword,
                meta_description: values.meta_description,
                seoName: values.seoName,
            }
            dispatch(onUpdateCity(updateCity));
            history('/cities');
            validation.resetForm();
          } else {
            const newCity = {
                name: values['name'],
                status: values['status'],
                projectId: 3,
                siteId: sessionStorage.getItem('siteid'),
                meta_title: values['meta_title'],
                meta_keyword: values['meta_keyword'],
                meta_description: values['meta_description'],
                seoName: values['seoName'],
            };
            // save new city
            dispatch(onAddNewCity(newCity));
            validation.resetForm();
          }
        },
          
    });

    useEffect(() => {
        if (typeof cityid != 'undefined' && !!cityid) {
          const fetchedElem = cities.find(
            (elem) => elem.id == cityid
          );
          if (typeof fetchedElem != 'undefined') {
            setCurrentCity({ ...fetchedElem });
            setIsEdit(true);
          } else {
            history('/cities');
          }
        }
    }, [cityid]);

    const editorHandler = (event, editor) => {
        // Get the editor content
        var content = editor.getData();
      
        // Remove empty paragraphs
        content = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
      
        // Set the field value with the updated content
        validation.setFieldValue('field1', content);
    };

    const editorHandler2 = (event, editor) => {
        // Get the editor content
        var content = editor.getData();
      
        // Remove empty paragraphs
        content = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
      
        // Set the field value with the updated content
        validation.setFieldValue('field2', content);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb
                    title={!!isEdit ? 'Update City' : 'Add City'}
                    pageTitle="Dashboard"
                />
                {/* Form Starts */}
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        {/* Left Section */}
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                <h5 className="card-title mb-0">
                                    {!!isEdit ? 'City Edit details' : 'Add City'}
                                </h5>
                                </CardHeader>
                                <CardBody>
                                    {/* Row 1 */}
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <Label
                                                    className="form-label"
                                                    htmlFor="plan-title-input"
                                                >
                                                    City Name*
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="plan-title-input"
                                                    placeholder="Enter plan title"
                                                    name="name"
                                                    value={validation.values.name || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.name && validation.touched.name
                                                        ? true
                                                        : false
                                                    }
                                                />
                                                {validation.errors.name && validation.touched.name ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.name}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* Row 2 */}
                                    <Row style={{ marginBottom : 30 }}>
                                        <Col>
                                        <Label>City Top Data</Label>
                                        <CKEditor
                                            editor={Editor}
                                            data={validation.values.field1 || ''}
                                            onChange={editorHandler}
                                        />
                                        </Col>
                                    </Row>
                                    {/* Row 3 */}
                                    <Row>
                                        <Col>
                                        <Label>City Bottom Data</Label>
                                        <CKEditor
                                            editor={Editor}
                                            data={validation.values.field2 || ''}
                                            onChange={editorHandler2}
                                        />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        
                            <div className="text-end mb-3">
                                <button type="submit" className="btn btn-success w-sm">
                                {!!isEdit ? 'Update' : 'Submit'}
                                </button>
                            </div>
                        </Col>

                        {/* Right Section */}
                        <Col lg={4}>
                            
                            {/* Meta Data Section */}
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Meta Data</h5>
                                </CardHeader>
                                <CardBody>
                                    
                                    <div className="mb-3">
                                        <Label htmlFor="status-field" className="form-label">
                                        Status
                                        </Label>

                                        <Input
                                        name="status"
                                        type="select"
                                        className="form-select"
                                        id="status-field"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.status || ""}
                                        invalid={
                                            validation.errors.status && validation.touched.status
                                            ? true
                                            : false
                                        }
                                        >
                                        {StatusMenu.map((item, key) => (
                                            <React.Fragment key={key}>
                                            {item.options.map((item, key) => (
                                                <option value={item.value} key={key}>
                                                {item.label}
                                                </option>
                                            ))}
                                            </React.Fragment>
                                        ))}
                                        </Input>
                                        {validation.touched.status && validation.errors.status ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.status}
                                        </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="meta-title-input">
                                            Meta title
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter meta title"
                                            id="meta-title-input"
                                            name="meta_title"
                                            value={validation.values.meta_title || ''}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.errors.meta_title &&
                                                validation.touched.meta_title
                                                ? true
                                                : false
                                            }
                                        />
                                        {validation.errors.meta_title &&
                                        validation.touched.meta_title ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.meta_title}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="meta-keywords-input">
                                            Meta Keywords
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter meta keywords"
                                            id="meta-keywords-input"
                                            name="meta_keyword"
                                            value={validation.values.meta_keyword || ''}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.errors.meta_keyword &&
                                                validation.touched.meta_keyword
                                                ? true
                                                : false
                                            }
                                        />
                                        {validation.errors.meta_keyword &&
                                        validation.touched.meta_keyword ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.meta_keyword}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div>
                                        <Label
                                            className="form-label"
                                            htmlFor="meta-description-input"
                                        >
                                            Meta Description
                                        </Label>
                                        <Input
                                            type="textarea"
                                            className="form-control"
                                            id="meta-description-input"
                                            placeholder="Enter meta description"
                                            name="meta_description"
                                            rows="3"
                                            value={validation.values.meta_description || ''}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.errors.meta_description &&
                                                validation.touched.meta_description
                                                ? true
                                                : false
                                            }
                                        ></Input>
                                        {validation.errors.meta_description &&
                                        validation.touched.meta_description ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.meta_description}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </CardBody>
                            </Card>
                            {/* Card of Seo Name */}
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">SEO Name</h5>
                                </CardHeader>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label
                                            className="form-label"
                                            htmlFor="provider-seoname-input"
                                        >
                                            Provider SEO Name
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="provider-seoname-input"
                                            placeholder="Enter seo name"
                                            name="seoName"
                                            value={validation.values.seoName || ''}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.errors.seoName &&
                                                validation.touched.seoName
                                                ? true
                                                : false
                                            }
                                        />
                                        {validation.errors.seoName &&
                                        validation.touched.seoName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.seoName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </CardBody>
                            </Card>
                            {/* Card of Seo Name */}
                        </Col>
                    </Row>
                    </Form>
            </Container>
        </div>
    )
}

export default AddCity

