import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getBroadbandPlans as getBroadbandPlansApi,
  addNewBroadbandPlan as addNewBroadbandPlanApi,
  updateBroadbandPlan as updateBroadbandPlanApi,
} from '../../helpers/backend_helper';

export const getBroadbandPlans = createAsyncThunk(
  'getBroadbandPlans',
  async () => {
    try {
      const response = getBroadbandPlansApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateBroadbandPlan = createAsyncThunk(
  'updateBroadbandPlan',
  async (broadbandPlan) => {
    try {
      const response = updateBroadbandPlanApi(broadbandPlan);
      const data = await response;
      toast.success('BroadbandPlan Updateded Successfully', {
        autoClose: 3000,
      });
      return data;
    } catch (error) {
      toast.error('BroadbandPlan Updateded Failed', { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewBroadbandPlan = createAsyncThunk(
  'addNewBroadbandPlan',
  async (broadbandPlan) => {
    try {
      const response = addNewBroadbandPlanApi(broadbandPlan);
      const data = await response;
      toast.success('BroadbandPlan Added Successfully', {
        autoClose: 3000,
      });
      return data;
    } catch (error) {
      toast.error('BroadbandPlan Added Failed', { autoClose: 3000 });
      return error;
    }
  }
);
