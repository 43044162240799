import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import {
  getDomains as onGetDomains,
  getProviders as onGetProviders,
  getBroadbandPlans as onGetBroadbandPlans,
} from '../../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import illustarator1 from '../../assets/images/user-illustarator-1.png';
import FeatherIcon from 'feather-icons-react';
import CountUp from 'react-countup';

const Dashboard = () => {
  document.title = 'Dashboard';
  const dispatch = useDispatch();
  const {
    user,
    domains,
    isDomainSuccess,
    providers,
    isProviderSuccess,
    broadbandPlans,
    isBroadbandPlanSuccess,
  } = useSelector((state) => ({
    user: state.Profile.user,
    domains: state.Domains.domains,
    isDomainSuccess: state.Domains.isDomainSuccess,
    providers: state.Providers.providers,
    isProviderSuccess: state.Providers.isProviderSuccess,
    broadbandPlans: state.BroadbandPlans.broadbandPlans,
    isBroadbandPlanSuccess: state.BroadbandPlans.isBroadbandPlanSuccess,
  }));
  const [domain, setDomain] = useState([]);
  const [provider, setProvider] = useState([]);
  const [broadbandPlan, setBroadbandPlan] = useState([]);

  const [userName, setUserName] = useState('Admin');

  const currentPanel = (domain) => {
    let panel = domain.find(function (obj) {
      return obj.id == sessionStorage.getItem('siteid') && obj.domainName;
    });
    return !!panel ? panel.domainName : '';
  };

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'));
      setUserName(obj.username ? obj.username : 'Admin');
    }
  }, [userName, user]);

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    setDomain(domains);
  }, [domains]);

  useEffect(() => {
    if (providers && !isProviderSuccess) {
      dispatch(onGetProviders());
    }
  }, [dispatch, providers]);

  useEffect(() => {
    setProvider(providers);
  }, [providers]);

  useEffect(() => {
    if (broadbandPlans && !isBroadbandPlanSuccess) {
      dispatch(onGetBroadbandPlans());
    }
  }, [dispatch, broadbandPlans]);

  useEffect(() => {
    setBroadbandPlan(broadbandPlans);
  }, [broadbandPlans]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="broadband.asia" />
          <Row className="mb-3 pb-1">
            <Col xs={12}>
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h4 className="fs-16 mb-1">Hey, {userName}!</h4>
                  <p className="text-muted mb-0">
                    Here's the sneak peak of your data.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card>
                <CardBody className="p-0">
                  <Row className="align-items-end">
                    <Col sm={8}>
                      <div className="p-3 py-2">
                        <p className="fs-16 lh-base">
                          You're logged in to{' '}
                          <span className="fw-semibold">
                            {currentPanel(domain)}
                          </span>{' '}
                          admin panel <i className="mdi mdi-arrow-right"></i>
                        </p>
                        <div className="mt-3">
                          <Link to="/logout" className="btn btn-danger">
                            Logout to access other panel
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="px-3">
                        <img src={illustarator1} className="img-fluid" alt="" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title rounded-2 fs-2 bg-success">
                        <FeatherIcon icon="briefcase" />
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                        Registered Merchant
                      </p>
                      <div className="d-flex align-items-center mb-3">
                        <h4 className="fs-1 flex-grow-1 mb-0">
                          <span
                            className="counter-value me-1"
                            data-target={provider.length}
                          >
                            <CountUp
                              start={0}
                              suffix=""
                              separator=","
                              end={provider.length}
                              duration={4}
                            />
                          </span>
                        </h4>
                      </div>
                      <p className="text-muted text-truncate mb-0">
                        <Link
                          to="/providers"
                          className="text-decoration-underline"
                        >
                          Manage Merchant
                        </Link>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card className="card-animate">
                <CardBody>
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title rounded-2 fs-2 bg-success">
                        <FeatherIcon icon="wifi" />
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-3">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                        Registered Broadband Plans
                      </p>
                      <div className="d-flex align-items-center mb-3">
                        <h4 className="fs-1 flex-grow-1 mb-0">
                          <span
                            className="counter-value me-1"
                            data-target={broadbandPlan.length}
                          >
                            <CountUp
                              start={0}
                              suffix=""
                              separator=","
                              end={broadbandPlan.length}
                              duration={4}
                            />
                          </span>
                        </h4>
                      </div>
                      <p className="text-muted text-truncate mb-0">
                        <Link to="/plans" className="text-decoration-underline">
                          Manage Broadband Plans
                        </Link>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
