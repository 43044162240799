import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { getReportAbuses as onGetReportAbuses } from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';
import { Published } from '../../Components/Common/Utils';

const ReportAbuses = () => {
  const dispatch = useDispatch();

  const { reportAbuses, isReportAbuseSuccess, error } = useSelector(
    (state) => ({
      reportAbuses: state.ReportAbuses.reportAbuses,
      isReportAbuseSuccess: state.ReportAbuses.isReportAbuseSuccess,
      error: state.ReportAbuses.error,
    })
  );

  const [reportAbuse, setReportAbuse] = useState([]);

  useEffect(() => {
    if (reportAbuses && !isReportAbuseSuccess) {
      dispatch(onGetReportAbuses());
    }
  }, [dispatch, reportAbuses]);

  useEffect(() => {
    setReportAbuse(reportAbuses);
  }, [reportAbuses]);

  useEffect(() => {
    if (!isEmpty(reportAbuses)) {
      setReportAbuse(reportAbuses);
    }
  }, [reportAbuses]);

  // ReportAbuses Column
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: 'Message',
        accessor: 'data',
        filterable: false,
      },
      {
        Header: 'Published',
        accessor: 'reportDate',
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: 'Plan Id',
        accessor: 'entityId',
        filterable: false,
      },
      {
        Header: 'Manage Plan',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Manage Plan">
                <Link
                  to={'/plans/edit/' + cellProps.row.original.entityId}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-pencil-fill fs-16"></i> Manage Plan
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Plans Abuses Reported | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={reportAbuses}
        />

        <Container fluid>
          <BreadCrumb title="Report Abuses" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="reportAbuseList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Report Abuse List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-info ms-1"
                          onClick={() => setIsExportCSV(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isReportAbuseSuccess ? (
                      <>
                        {reportAbuses.length ? (
                          <TableContainer
                            columns={columns}
                            data={reportAbuses || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            className="custom-header-css"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for reportAbuse..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportAbuses;
