import { createSlice } from '@reduxjs/toolkit';
import { getReportAbuses } from './thunk';
export const initialState = {
  reportAbuses: [],
  error: {},
};

const ReportAbusesSlice = createSlice({
  name: 'ReportAbusesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getReportAbuses.fulfilled, (state, action) => {
      state.reportAbuses = action.payload.payload;
      state.isReportAbuseCreated = false;
      state.isReportAbuseSuccess = true;
    });

    builder.addCase(getReportAbuses.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isReportAbuseCreated = false;
      state.isReportAbuseSuccess = false;
    });
  },
});

export default ReportAbusesSlice.reducer;
