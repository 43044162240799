import { createSlice } from '@reduxjs/toolkit';
import {
  getManageWidgets,
  addNewManageWidget,
  updateManageWidget,
} from './thunk';
export const initialState = {
  manageWidgets: [],
  error: {},
};

const ManageWidgetsSlice = createSlice({
  name: 'ManageWidgetsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getManageWidgets.fulfilled, (state, action) => {
      state.manageWidgets = action.payload.payload;
      state.isManageWidgetCreated = false;
      state.isManageWidgetSuccess = true;
    });

    builder.addCase(getManageWidgets.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isManageWidgetCreated = false;
      state.isManageWidgetSuccess = false;
    });

    builder.addCase(addNewManageWidget.fulfilled, (state, action) => {
      state.manageWidgets.push(action.payload.payload);
      state.isManageWidgetCreated = true;
    });
    builder.addCase(addNewManageWidget.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateManageWidget.fulfilled, (state, action) => {
      state.manageWidgets = state.manageWidgets.map((manageWidget) =>
        manageWidget.id.toString() === action.payload.payload.id.toString()
          ? { ...manageWidget, ...action.payload.payload }
          : manageWidget
      );
    });
    builder.addCase(updateManageWidget.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default ManageWidgetsSlice.reducer;
