import {
    Card,
    CardBody,
    Input,
    Label,
    FormFeedback,
    CardHeader,
  } from 'reactstrap';

const ContactBoxFormComponents = ({validation, valueKey})=>{
   

  const labelValue = validation.values[`label${valueKey}`] || '';
  const descValue = validation.values[`desc${valueKey}`] || '';
  const valValue = validation.values[`value${valueKey}`] || '';

    return (
        
            <Card>
                <CardHeader>
                <h5 className="card-title mb-0">
                    Contact Box {Number(valueKey)+1}
                </h5>
                </CardHeader>
                <CardBody>
                <div className="mb-3">
                    <Label
                    className="form-label"
                    htmlFor={`plan-label-input${valueKey}`}
                    >
                    Label
                    </Label>
                    <Input
                    name={`label${valueKey}`}
                    type="text"
                    className="form-control"
                    id={`plan-label-input${valueKey}`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={labelValue}
                    invalid={
                        validation.errors[`${`label${valueKey}`}`] && validation.touched[`${`label${valueKey}`}`]
                          ? true
                          : false
                      }
                    />
                    {validation.errors[`${`label${valueKey}`}`] && validation.touched[`${`label${valueKey}`}`] ? (
                        <FormFeedback type="invalid">
                        {validation.errors[`${`label${valueKey}`}`]}
                        </FormFeedback>
                    ) : null}
                </div>

                <div className="mb-3">
                    <Label
                    className="form-desc"
                    htmlFor={`plan-desc-input${valueKey}`}
                    >
                    Description
                    </Label>
                    <Input
                    name={`desc${valueKey}`}
                    type="text"
                    className="form-control"
                    id={`plan-desc-input${valueKey}`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={descValue}
                    invalid={
                        validation.errors[`${`desc${valueKey}`}`] && validation.touched[`${`desc${valueKey}`}`]
                          ? true
                          : false
                      }
                    />
                    {validation.errors[`${`desc${valueKey}`}`] && validation.touched[`${`desc${valueKey}`}`] ? (
                        <FormFeedback type="invalid">
                        {validation.errors[`${`desc${valueKey}`}`]}
                        </FormFeedback>
                    ) : null}
                   
                </div>

                <div className="mb-3">
                    <Label
                    className="form-value"
                    htmlFor={`plan-value-input${valueKey}`}
                    >
                    Value
                    </Label>
                    <Input
                    name={`value${valueKey}`}
                    type="text"
                    className="form-control"
                    id={`plan-value-input${valueKey}`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={valValue}
                    invalid={
                        validation.errors[`${`value${valueKey}`}`] && validation.touched[`${`value${valueKey}`}`]
                          ? true
                          : false
                      }
                    />
                    {validation.errors[`${`value${valueKey}`}`] && validation.touched[`${`value${valueKey}`}`] ? (
                        <FormFeedback type="invalid">
                        {validation.errors[`${`value${valueKey}`}`]}
                        </FormFeedback>
                    ) : null}
                </div>
                </CardBody>
            </Card>
       
    )
}

export default ContactBoxFormComponents;