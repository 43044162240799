import React from 'react';
import { Navigate } from 'react-router-dom';

//Dashboard
import Dashboard from '../pages/Dashboard';
//login
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
// User Profile
import UserProfile from '../pages/Authentication/user-profile';

//Pages
import Domains from '../pages/Domains';
import Countries from '../pages/Countries';
import AddCountry from '../pages/Countries/[slug]';
import Cities from '../pages/Cities';
import AddCity from '../pages/Cities/[slug]';
import BroadbandAttributes from '../pages/BroadbandAttributes';
import AddBroadbandAttributes from '../pages/BroadbandAttributes/[slug]';
import Providers from '../pages/Providers';
import BroadbandPlans from '../pages/BroadbandPlans';
import AddProvider from '../pages/Providers/[slug]';
import AddBroadbandPlans from '../pages/BroadbandPlans/[slug]';
import ManageHomepage from '../pages/ManageHomepage';
import ProvidersWidgets from '../pages/ProvidersWidgets';
import AddProvidersWidget from '../pages/ProvidersWidgets/[slug]';
import ManageWidgets from '../pages/ManageWidgets';
import ManageWidgetCards from '../pages/ManageWidgetCards';
import AddManageWidget from '../pages/ManageWidgets/[slug]';
import ManageContact from '../pages/ManageContact';
import ManageAbout from '../pages/ManageAbout';
import ReportAbuses from '../pages/ReportAbuses';
import Reviews from '../pages/Reviews';
import ManageCompareBroadband from '../pages/ManageCompareBroadband';
import ManageHighSpeedPlan from '../pages/ManageHighSpeedPlan';
import ManageCheapestPlan from '../pages/ManageCheapestPlan';
import ManageTopEntertainmentPlan from '../pages/ManageTopEntertainmentPlan';
import ManageTermsCondition from '../pages/ManageTerms';
import ManagePrivacyPolicy from '../pages/ManagePolicy';
import Leads from '../pages/Leads';

const authProtectedRoutes = [
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/domains', component: <Domains /> },
  { path: '/countries', component: <Countries /> },
  { path: '/countries/add', component: <AddCountry /> },
  { path: '/countries/edit/:countryid', component: <AddCountry /> },
  { path: '/cities', component: <Cities /> },
  { path: '/cities/add', component: <AddCity /> },
  { path: '/cities/edit/:cityid', component: <AddCity /> },
  { path: '/broadband-attributes', component: <BroadbandAttributes /> },
  { path: '/broadband-attributes/add', component: <AddBroadbandAttributes /> },
  {
    path: '/broadband-attributes/edit/:attributeid',
    component: <AddBroadbandAttributes />,
  },
  { path: '/providers', component: <Providers /> },
  { path: '/providers/add', component: <AddProvider /> },
  { path: '/providers/edit/:providerid', component: <AddProvider /> },
  { path: '/plans', component: <BroadbandPlans /> },
  { path: '/plans/add', component: <AddBroadbandPlans /> },
  { path: '/plans/edit/:planid', component: <AddBroadbandPlans /> },

  { path: '/manage-homepage', component: <ManageHomepage /> },
  { path: '/manage-widgets', component: <ManageWidgets /> },
  { path: '/manage-widgets/add', component: <AddManageWidget /> },
  {
    path: '/manage-widgets/edit/:manageWidgetid',
    component: <AddManageWidget />,
  },
  {
    path: '/manage-widget-cards/:wizardid',
    component: <ManageWidgetCards />,
  },

  { path: '/providers-widgets', component: <ProvidersWidgets /> },
  { path: '/providers-widgets/add', component: <AddProvidersWidget /> },
  {
    path: '/providers-widgets/edit/:providersWidgetid',
    component: <AddProvidersWidget />,
  },
  // {
  //   path: '/providers-widget-cards/:wizardid',
  //   component: <ProvidersWidgetCards />,
  // },
  
  { path: '/manage-contact', component: <ManageContact /> },
  { path: '/manage-about', component: <ManageAbout /> },
  { path: '/manage-compare-broadband', component: <ManageCompareBroadband /> },
  { path: '/manage-highspeed-plan', component: <ManageHighSpeedPlan /> },
  { path: '/manage-cheapest-plan', component: <ManageCheapestPlan /> },
  {
    path: '/manage-top-entertainment-plans',
    component: <ManageTopEntertainmentPlan />,
  },
  {
    path: '/manage-terms-conditions',
    component: <ManageTermsCondition />,
  },
  {
    path: '/manage-privacy-policy',
    component: <ManagePrivacyPolicy />,
  },
  { path: '/manage-leads', component: <Leads /> },
  { path: '/reviews', component: <Reviews /> },
  { path: '/abuses-reported', component: <ReportAbuses /> },

  //User Profile
  { path: '/profile', component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: '*', component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
