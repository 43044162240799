import { createAsyncThunk } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getLeads as getLeadsApi } from '../../helpers/backend_helper';

export const getLeads = createAsyncThunk('getLeads', async () => {
  try {
    const response = getLeadsApi();
    return response;
  } catch (error) {
    return error;
  }
});
