import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
    getProvidersWidgets as getProvidersWidgetsApi,
    addNewProvidersWidget as addNewProvidersWidgetApi,
    updateProvidersWidget as updateProvidersWidgetApi,
} from '../../helpers/backend_helper';
  
export const getProvidersWidgets = createAsyncThunk(
    'getProvidersWidgets',
    async () => {
      try {
        const response = getProvidersWidgetsApi();
        return response;
      } catch (error) {
        return error;
      }
    }
);
  
export const updateProvidersWidget = createAsyncThunk(
    'updateProvidersWidget',
    async (providersWidget) => {
      try {
        const response = updateProvidersWidgetApi(providersWidget);
        const data = await response;
        toast.success('Widget Updated Successfully', { autoClose: 3000 });
        return data;
      } catch (error) {
        toast.error('Widget Updation Failed', { autoClose: 3000 });
        return error;
      }
    }
);
  
export const addNewProvidersWidget = createAsyncThunk(
    'addNewProvidersWidget',
    async (providersWidget) => {
      try {
        const response = addNewProvidersWidgetApi(providersWidget);
        const data = await response;
        toast.success('Widget Added Successfully', { autoClose: 3000 });
        return data;
      } catch (error) {
        toast.error('Widget Addition Failed', { autoClose: 3000 });
        return error;
      }
    }
);