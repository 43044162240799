//LOGIN
export const POST_LOGIN = '/admin/adminUser/login';

//PROFILE
export const POST_EDIT_PROFILE = '/user';

// Manage Leads
export const GET_LEADS = '/admin/web/leadform/list';

// Reported Abuses
export const GET_REPORTABUSES = '/admin/ReportAbuse/list';

// Reviews
export const GET_REVIEWS = '/admin/review/list';
export const UPDATE_REVIEW = '/admin/review/save';

// Cities
export const GET_DOMAINS = '/admin/site/list/3';
export const ADD_NEW_DOMAIN = '/admin/site/save';
export const UPDATE_DOMAIN = '/admin/site/save';

// Countries
export const GET_COUNTRIES = '/admin/country/list';
export const ADD_NEW_COUNTRY = '/admin/country/save';
export const UPDATE_COUNTRY = '/admin/country/save';

// Cities
export const GET_CITIES = '/admin/city/list';
export const ADD_NEW_CITY = '/admin/city/save';
export const UPDATE_CITY = '/admin/city/save';
//export const FIND_CITIES_FROM_BROADBAND_ID = '/admin/citybroadbandrel/findCityByBroadbandId';
export const FIND_CITIES_FROM_BROADBAND_ID = '/admin/broadband/find';
export const UPDATE_CITIES_FROM_BROADBAND_ID = '/admin/citybroadbandrel/save';
export const DELETE_CITIES_FROM_BROADBAND_ID = '/admin/citybroadbandrel/delete';

// Provider
export const GET_PROVIDERS = '/admin/provider/listWithCity';
export const ADD_NEW_PROVIDER = '/admin/provider/saveWithCity';
export const UPDATE_PROVIDER = '/admin/provider/saveWithCity';

// BroadBandPlans
export const GET_BROADBANDPLANS = '/admin/broadband/list';
export const ADD_NEW_BROADBANDPLAN = '/admin/broadband/saveWithAttr';
export const UPDATE_BROADBANDPLAN = '/admin/broadband/saveWithAttr';

// BroadBandAttributes
export const GET_BROADBAND_ATTRIBUTES = '/admin/broadbandAttribute/list';
export const ADD_NEW_BROADBAND_ATTRIBUTE = '/admin/broadbandAttribute/save';
export const UPDATE_BROADBAND_ATTRIBUTE = '/admin/broadbandAttribute/save';

// Keys
export const GET_KEYS = '/admin/admin/cacheByprojectId/3/key';
export const UPDATE_KEY = '/admin/admin/save';

// Manage Widgets
export const GET_MANAGEWIDGETS = '/admin/wizard/list';
export const ADD_NEW_MANAGEWIDGET = '/admin/wizard/saveWizard';
export const UPDATE_MANAGEWIDGET = '/admin/wizard/saveWizard';

// Providers Widgets
export const GET_PROVIDERSWIDGETS = '/admin/wizard/list';
export const ADD_NEW_PROVIDERSWIDGET = '/admin/wizard/saveWizard';
export const UPDATE_PROVIDERSWIDGET = '/admin/wizard/saveWizard';

// Manage Widget Cards
export const GET_MANAGEWIDGETCARDS = '/admin/wizard/findWizardItems';
export const ADD_NEW_MANAGEWIDGETCARD = '/admin/wizard/saveWizardItem';
export const UPDATE_MANAGEWIDGETCARD = '/admin/wizard/saveWizardItem';

//Cache
export const CLEAR_CACHE = '/admin/cache/cacheClean';