import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';
// status
import AttributeTypes from '../../Data/attributeType.json';
// colorCodeList
import ColorCodeList from '../../Data/colorCode.json';
import IconList from '../../Data/iconOptions.json';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewBroadbandAttribute as onAddNewBroadbandAttribute,
  updateBroadbandAttribute as onUpdateBroadbandAttribute,
} from '../../slices/thunks';

import { useNavigate, useParams } from 'react-router-dom';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PreviewImage from '../../Components/Common/PreviewImage';
import axios from 'axios';

const AddBroadbandAttribut = () => {
  const { attributeid } = useParams();
  document.title = 'Broadband Attributes | Admin & Dashboard';

  const history = useNavigate();
  const dispatch = useDispatch();

  const { broadbandAttributes } = useSelector((state) => ({
    broadbandAttributes: state.BroadbandAttributes.broadbandAttributes,
  }));

  const [broadbandAttibute, setBroadbandAttibute] = useState([]);
  const [attibuteType, setAttibuteType] = useState('btn');
  const [isEdit, setIsEdit] = useState(false);

  const attributeHandler = (event) => {
    validation.setFieldValue('type', event.target.value);
    setAttibuteType(event.target.value);
  };

  const imageHandler = (event) => {
    validation.setFieldValue('imageUrl', event.target.files[0]);
  };

  const uploadToCloudinary = async (file) => {
    const img_file = file,
      formData = new FormData();
    formData.append('file', img_file);
    formData.append('upload_preset', 'broadband');
    const uninterceptedAxiosInstance = axios.create();
    var res = await uninterceptedAxiosInstance.post(
      'https://api.cloudinary.com/v1_1/dxzr7l0pe/image/upload',
      formData
    );
    return res.data.secure_url;
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (broadbandAttibute && broadbandAttibute.name) || '',
      colorCode: (broadbandAttibute && broadbandAttibute.colorCode) || '',
      imageUrl: (broadbandAttibute && broadbandAttibute.imageUrl) || '',
      type: (broadbandAttibute && broadbandAttibute.type) || '',
      toolTip: (broadbandAttibute && broadbandAttibute.toolTip) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a provider name'),
      type: Yup.string().required('Select type of attribute'),
    }),
    onSubmit: async (values) => {
      let imageUrl = values.imageUrl;
      if (
        typeof imageUrl?.name != 'undefined' &&
        typeof imageUrl?.name == 'string'
      ) {
        imageUrl = await uploadToCloudinary(imageUrl);
      }

      if (isEdit) {
        const updateAtttribute = {
          id: broadbandAttibute ? broadbandAttibute.id : attributeid,
          name: values.name,
          type: values.type,
          colorCode: values.colorCode,
          imageUrl: imageUrl,
          toolTip: values.toolTip,
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // update onUpdateBroadbandAttribute
        dispatch(onUpdateBroadbandAttribute(updateAtttribute));
        history('/broadband-attributes');
        validation.resetForm();
      } else {
        const newProvider = {
          name: values['name'],
          type: values['type'],
          colorCode: values['colorCode'],
          imageUrl: imageUrl,
          toolTip: values['toolTip'],
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // save new onAddNewBroadbandAttribute
        dispatch(onAddNewBroadbandAttribute(newProvider));
        history('/broadband-attributes');
        validation.resetForm();
      }
    },
  });

  useEffect(() => {
    if (typeof attributeid != 'undefined' && !!attributeid) {
      const fetchedElem = broadbandAttributes.find(
        (elem) => elem.id == attributeid
      );
      if (typeof fetchedElem != 'undefined') {
        setBroadbandAttibute({ ...fetchedElem });
        setAttibuteType(fetchedElem.type || '');
        setIsEdit(true);
      } else {
        history('/broadband-attributes');
      }
    }
  }, [attributeid]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={!!isEdit ? 'Update Attibutes' : 'Add Attributes'}
          pageTitle="Dashboard"
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Select Attribute Type*</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="mb-3">
                      <Label htmlFor="type-field" className="form-label">
                        Attribute Type
                      </Label>

                      <Input
                        name="type"
                        type="select"
                        className="form-select"
                        id="type-field"
                        onChange={attributeHandler}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ''}
                        invalid={
                          validation.errors.type && validation.touched.type
                            ? true
                            : false
                        }
                      >
                        {AttributeTypes.map((item, key) => (
                          <React.Fragment key={key}>
                            {item.options.map((item, key) => (
                              <option value={item.value} key={key}>
                                {item.label}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="mb-3">
                      <div className="mb-3">
                        <Label
                          htmlFor="BroadbandAttributename-field"
                          className="form-label"
                        >
                          Broadband Attribute Name*
                        </Label>
                        <Input
                          name="name"
                          id="BroadbandAttributename-field"
                          className="form-control"
                          placeholder="Enter attribute name like Featured Deals, Recommended"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ''}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Button Attribute Options</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    {attibuteType === 'btn' && (
                      <Col className="mb-3">
                        <Label htmlFor="colorCode-field" className="form-label">
                          Button Type*
                        </Label>

                        <Input
                          name="colorCode"
                          type="select"
                          className="form-select"
                          id="colorCode-field"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.colorCode || ''}
                          invalid={
                            validation.touched.colorCode &&
                            validation.errors.colorCode
                              ? true
                              : false
                          }
                        >
                          {ColorCodeList.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.touched.colorCode &&
                        validation.errors.colorCode ? (
                          <FormFeedback type="invalid">
                            {validation.errors.colorCode}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    )}
                    {attibuteType === 'icon' && (
                      <Col className="mb-3">
                        <Label htmlFor="colorCode-field" className="form-label">
                          Select Icon*
                        </Label>

                        <Input
                          name="colorCode"
                          type="select"
                          className="form-select"
                          id="colorCode-field"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.colorCode || ''}
                          invalid={
                            validation.touched.colorCode &&
                            validation.errors.colorCode
                              ? true
                              : false
                          }
                        >
                          {IconList.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.touched.colorCode &&
                        validation.errors.colorCode ? (
                          <FormFeedback type="invalid">
                            {validation.errors.colorCode}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    )}
                    {attibuteType === 'ott' && (
                      <Col className="mb-3">
                        <h5 className="fs-15 mb-1">OTT Image</h5>
                        <p className="text-muted">167 X 55 Image</p>
                        <div>
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="product-image-input"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Image"
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="product-image-input"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={imageHandler}
                              />
                            </div>
                            <div className="plan-main-img">
                              {validation.values.imageUrl &&
                              validation.values.imageUrl.name ? (
                                <PreviewImage
                                  file={validation.values.imageUrl}
                                />
                              ) : (
                                <div className="avatar-title bg-light rounded">
                                  <img
                                    src={validation.values.imageUrl || ''}
                                    alt=""
                                    className="h-auto"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {validation.errors.imageUrl &&
                        validation.touched.imageUrl ? (
                          <FormFeedback type="invalid">
                            {validation.errors.imageUrl}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    )}
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="toolTip-field" className="form-label">
                          Tooltip (optional)
                        </Label>
                        <Input
                          name="toolTip"
                          type="text"
                          id="toolTip-field"
                          placeholder="Enter tooltip text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.toolTip || ''}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button type="submit" className="btn btn-success w-sm">
                  {!!isEdit ? 'Update' : 'Submit'}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddBroadbandAttribut;
