import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
  getReviews as onGetReviews,
  updateReview as onUpdateReview,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// status
import StatusMenu from '../../Data/status.json';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';
import { Published } from '../../Components/Common/Utils';

const Reviews = () => {
  const dispatch = useDispatch();

  const { reviews, isReviewSuccess, error } = useSelector((state) => ({
    reviews: state.Reviews.reviews,
    isReviewSuccess: state.Reviews.isReviewSuccess,
    error: state.Reviews.error,
  }));

  const [review, setReview] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setReview(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      review: (review && review.review) || '',
      status: (review && review.status) || '0',
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Please select status'),
    }),
    onSubmit: (values) => {
      const updateReview = {
        id: review ? review.id : 0,
        name: review.name,
        review: review.review,
        reviewDate: review.reviewDate,
        entityId: review.entityId,
        category: review.category,
        status: parseInt(values.status),
        projectId: 3,
        siteId: sessionStorage.getItem('siteid'),
      };
      // update review
      dispatch(onUpdateReview(updateReview));
      validation.resetForm();
      toggle();
    },
  });
  // Update Data
  const handleReviewClick = useCallback(
    (arg) => {
      const review = arg;
      setReview({
        id: review.id,
        name: review.name,
        review: review.review,
        reviewDate: review.reviewDate,
        entityId: review.entityId,
        category: review.category,
        status: review.status,
        projectId: 3,
        siteId: review.siteId,
      });
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (reviews && !isReviewSuccess) {
      dispatch(onGetReviews());
    }
  }, [dispatch, reviews]);

  useEffect(() => {
    setReview(reviews);
  }, [reviews]);

  useEffect(() => {
    if (!isEmpty(reviews)) {
      setReview(reviews);
    }
  }, [reviews]);

  // Reviews Column
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: 'Review Text',
        accessor: 'review',
        filterable: true,
      },
      {
        Header: 'Published',
        accessor: 'reviewDate',
        filterable: true,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (cell) => {
          switch (cell.value) {
            case 1:
              return (
                <span className="badge text-uppercase badge-soft-success">
                  Active
                </span>
              );
            case 0:
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  Block
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-info">
                  {cell.value}
                </span>
              );
          }
        },
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const reviewData = cellProps.row.original;
                    handleReviewClick(reviewData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i> Edit
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleReviewClick]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Reviews | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={reviews}
        />

        <Container fluid>
          <BreadCrumb title="Reviews" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="reviewList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Review List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-info ms-1"
                          onClick={() => setIsExportCSV(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isReviewSuccess ? (
                      <>
                        {reviews.length ? (
                          <TableContainer
                            columns={columns}
                            data={reviews || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            className="custom-header-css"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for review..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      Manage Review Status
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />
                        <h6>Review</h6>
                        <p className="mb-3">{validation.values.review}</p>
                        <div
                          className="mb-3 mt-3"
                          id="modal-id"
                          style={{ display: 'none' }}
                        >
                          <Label htmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div>
                          <Label htmlFor="status-field" className="form-label">
                            Status
                          </Label>

                          <Input
                            name="status"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ''}
                            invalid={
                              validation.errors.status &&
                              validation.touched.status
                                ? true
                                : false
                            }
                          >
                            {StatusMenu.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            Update Review Status
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reviews;
