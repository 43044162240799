import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getProviders as getProvidersApi,
  addNewProvider as addNewProviderApi,
  updateProvider as updateProviderApi,
} from '../../helpers/backend_helper';

export const getProviders = createAsyncThunk('getProviders', async () => {
  try {
    const response = await getProvidersApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateProvider = createAsyncThunk(
  'updateProvider',
  async (provider) => {
    try {
      const response = await updateProviderApi(provider);
      toast.success('Provider Updateded Successfully', { autoClose: 3000 });
      return response;
      
    } catch (error) {
      toast.error('Provider Updateded Failed', { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewProvider = createAsyncThunk(
  'addNewProvider',
  async (provider) => {
    try {
      
      const data = await addNewProviderApi(provider);
      toast.success('Provider Added Successfully', { autoClose: 3000 });
      return data;
    } catch (error) {
      toast.error('Provider Added Failed', { autoClose: 3000 });
      return error;
    }
  }
);
