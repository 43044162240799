import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getDomains as getDomainsApi,
  addNewDomain as addNewDomainApi,
  updateDomain as updateDomainApi,
} from '../../helpers/backend_helper';

export const getDomains = createAsyncThunk('getDomains', async () => {
  try {
    const response = getDomainsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateDomain = createAsyncThunk('updateDomain', async (domain) => {
  try {
    const response = updateDomainApi(domain);
    const data = await response;
    toast.success('Domain Updateded Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('Domain Updateded Failed', { autoClose: 3000 });
    return error;
  }
});

export const addNewDomain = createAsyncThunk('addNewDomain', async (domain) => {
  try {
    const response = addNewDomainApi(domain);
    const data = await response;
    toast.success('Domain Added Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('Domain Added Failed', { autoClose: 3000 });
    return error;
  }
});
