import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isDomains, setIsDomains] = useState(false);
  const [isCountries, setIsCountries] = useState(false);
  const [isCities, setIsCities] = useState(false);
  const [isPlanAttributes, setIsPlanAttributes] = useState(false);
  const [isPlans, setIsPlans] = useState(false);
  const [isProvider, setIsProvider] = useState(false);
  const [isProvidersWidgets, setIsProvidersWidgets] = useState(false);
  const [isManageWidgets, setIsManageWidgets] = useState(false);
  const [isManageHomepage, setIsManageHomepage] = useState(false);
  const [isManageContact, setIsManageContact] = useState(false);
  const [isManageAbout, setIsManageAbout] = useState(false);
  const [isManageCompareBroadband, setIsManageCompareBroadband] =
    useState(false);
  const [isManageHighSpeedPlan, setIsManageHighSpeedPlan] = useState(false);
  const [isManageCheapestPlan, setIsManageCheapestPlan] = useState(false);
  const [isManageTopEntertainmentPlan, setIsManageTopEntertainmentPlan] =
    useState(false);
  const [isTermsConditions, setIsTermsConditions] = useState(false);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);
  const [isReportAbuses, setIsReportAbuses] = useState(false);
  const [isLeads, setIsLeads] = useState(false);
  const [isReviews, setIsReviews] = useState(false);
  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
  }, [history, iscurrentState, isDashboard]);

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'ri-honour-line',
      link: '/dashboard',
      click: function (e) {
        e.preventDefault();
        setIscurrentState('Dashboard');
      },
    },
    {
      id: 'domains',
      label: 'Domains',
      icon: 'ri-global-line',
      link: '/domains',
      click: function (e) {
        e.preventDefault();
        setIsDomains(!isDomains);
      },
    },
    {
      id: 'countries',
      label: 'Countries',
      icon: 'ri-map-pin-line',
      link: '/countries',
      click: function (e) {
        e.preventDefault();
        setIsCountries(!isCountries);
      },
    },
    {
      id: 'cities',
      label: 'Cities',
      icon: 'ri-map-pin-user-line',
      link: '/cities',
      click: function (e) {
        e.preventDefault();
        setIsCities(!isCities);
      },
    },
    {
      id: 'planattributes',
      label: 'Broadband Attributes',
      icon: 'ri-file-list-3-line',
      link: '/broadband-attributes',
      click: function (e) {
        e.preventDefault();
        setIsPlanAttributes(!isPlanAttributes);
      },
    },
    {
      id: 'providers',
      label: 'Broadband Providers',
      icon: 'ri-pages-line',
      link: '/providers',
      click: function (e) {
        e.preventDefault();
        setIsProvider(!isProvider);
      },
    },
    {
      id: 'plans',
      label: 'Broadband Plans',
      icon: 'ri-broadcast-line',
      link: '/plans',
      click: function (e) {
        e.preventDefault();
        setIsPlans(!isPlans);
      },
    },
    {
      label: 'Widget',
      isHeader: true,
    },
    {
      id: 'Providers-widgets',
      label: 'Providers Widgets',
      icon: 'ri-file-list-3-line',
      link: '/Providers-widgets',
      click: function (e) {
        e.preventDefault();
        setIsProvidersWidgets(!isProvidersWidgets);
      },
    },
    {
      id: 'manage-widgets',
      label: 'Manage Widgets',
      icon: 'ri-file-list-3-line',
      link: '/manage-widgets',
      click: function (e) {
        e.preventDefault();
        setIsManageWidgets(!isManageWidgets);
      },
    },
    {
      label: 'Manage Pages',
      isHeader: true,
    },
    {
      id: 'manage-homepage',
      label: 'Homepage',
      icon: 'ri-home-4-line',
      link: '/manage-homepage',
      click: function (e) {
        e.preventDefault();
        setIsManageHomepage(!isManageHomepage);
      },
    },
    {
      id: 'manage-contact',
      label: 'Contact',
      icon: 'ri-cellphone-line',
      link: '/manage-contact',
      click: function (e) {
        e.preventDefault();
        setIsManageContact(!isManageContact);
      },
    },
    {
      id: 'manage-about',
      label: 'About',
      icon: 'ri-article-line',
      link: '/manage-about',
      click: function (e) {
        e.preventDefault();
        setIsManageAbout(!isManageAbout);
      },
    },
    {
      id: 'manage-compare-broadband',
      label: 'Compare Broadband',
      icon: 'ri-router-line',
      link: '/manage-compare-broadband',
      click: function (e) {
        e.preventDefault();
        setIsManageCompareBroadband(!isManageCompareBroadband);
      },
    },
    {
      id: 'manage-highspeed-plan',
      label: 'High Speed Plan',
      icon: 'ri-send-plane-line',
      link: '/manage-highspeed-plan',
      click: function (e) {
        e.preventDefault();
        setIsManageHighSpeedPlan(!isManageHighSpeedPlan);
      },
    },
    {
      id: 'manage-cheapest-plan',
      label: 'Cheapest Plan',
      icon: 'ri-money-dollar-circle-line',
      link: '/manage-cheapest-plan',
      click: function (e) {
        e.preventDefault();
        setIsManageCheapestPlan(!isManageCheapestPlan);
      },
    },
    {
      id: 'manage-top-entertainment-plans',
      label: 'Top Entertainment Plan',
      icon: 'ri-play-list-2-line',
      link: '/manage-top-entertainment-plans',
      click: function (e) {
        e.preventDefault();
        setIsManageTopEntertainmentPlan(!isManageTopEntertainmentPlan);
      },
    },
    ,
    {
      id: 'manage-terms-conditions',
      label: 'Terms & Conditions',
      icon: 'ri-chat-private-line',
      link: '/manage-terms-conditions',
      click: function (e) {
        e.preventDefault();
        setIsTermsConditions(!isTermsConditions);
      },
    },
    ,
    {
      id: 'manage-privacy-policy',
      label: 'Privacy Policy',
      icon: 'ri-git-repository-private-line',
      link: '/manage-privacy-policy',
      click: function (e) {
        e.preventDefault();
        setIsPrivacyPolicy(!isPrivacyPolicy);
      },
    },
    {
      label: 'Miscellaneous',
      isHeader: true,
    },
    {
      id: 'manage-leads',
      label: 'Manage Leads',
      icon: 'ri-rocket-line',
      link: '/manage-leads',
      click: function (e) {
        e.preventDefault();
        setIsLeads(!isLeads);
      },
    },
    {
      id: 'reviews',
      label: 'Manage Reviews',
      icon: 'ri-shield-star-line',
      link: '/reviews',
      click: function (e) {
        e.preventDefault();
        setIsReviews(!isReviews);
      },
    },
    {
      id: 'report-abuse',
      label: 'Reported Plans',
      icon: 'ri-error-warning-line',
      link: '/abuses-reported',
      click: function (e) {
        e.preventDefault();
        setIsReportAbuses(!isReportAbuses);
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
