import { createAsyncThunk } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getReportAbuses as getReportAbusesApi } from '../../helpers/backend_helper';

export const getReportAbuses = createAsyncThunk('getReportAbuses', async () => {
  try {
    const response = getReportAbusesApi();
    return response;
  } catch (error) {
    return error;
  }
});
