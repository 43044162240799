import React, { useCallback, useEffect, useState } from 'react';

import Select from 'react-select';

import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewBroadbandPlan as onAddNewBroadbandPlan,
  updateBroadbandPlan as onUpdateBroadbandPlan,
  getBroadbandAttributes as onGetBroadbandAttributes,
  getProviders as onGetProviders,
  getCities as onGetCities,
} from '../../slices/thunks';

// status
import StatusMenu from '../../Data/status.json';
import BooleanStatusMenu from '../../Data/booleanStatus.json';
// CurrencyMenu Scale
import CurrencyMenu from '../../Data/currency.json';
// DataLimit Scale
import DataLimitScale from '../../Data/dataLimitScale.json';
// Duration Scale
import DurationScale from '../../Data/durationScale.json';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

import { useNavigate, useParams } from 'react-router-dom';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { findCityByBroadbandId, updateCityByBroadbandId, deleteCityByBroadbandId } from '../../helpers/backend_helper';

const AddBroadbandPlans = () => {
  const { planid } = useParams();
  document.title = 'Plan | Admin & Dashboard';

  let content = "";
  const history = useNavigate();
  const dispatch = useDispatch();

  const {
    broadbandPlans,
    providers,
    cities, 
    isCitySuccess,
    isProviderSuccess,
    broadbandAttributes,
    isBroadbandAttributeSuccess,
  } = useSelector((state) => ({
    broadbandPlans: state.BroadbandPlans.broadbandPlans,
    providers: state.Providers.providers,
    isProviderSuccess: state.Providers.isProviderSuccess,
    broadbandAttributes: state.BroadbandAttributes.broadbandAttributes,
    isBroadbandAttributeSuccess:
      state.BroadbandAttributes.isBroadbandAttributeSuccess,
    cities: state.Cities.cities,
    isCitySuccess: state.Cities.isCitySuccess,
  }));

  const [broadbandPlan, setBroadbandPlan] = useState([]);
  const [city, setCity] = useState([]);
  const [provider, setProvider] = useState([]);
  const [broadbandAttribute, setBroadbandAttribute] = useState([]);
  const [isEdit, setIsEdit] = useState(false); 
  const [foundCities, setFoundCities] = useState([]); 

  const handleUnlimited = (event) => {
    validation.setFieldValue(event.target.name, event.target.value);
    if (event.target.value === 'unlimited') {
      if (event.target.name === 'bandwidthSpeedScale') {
        validation.setFieldValue('bandwidth', -1);
        document
          .getElementById('plan-bandwidth-input')
          .setAttribute('disabled', 'disabled');
      } else if (event.target.name === 'speedScale') {
        validation.setFieldValue('speed', -1);
        document
          .getElementById('plan-speed-input')
          .setAttribute('disabled', 'disabled');
      }
    } else {
      if (event.target.name === 'bandwidthSpeedScale') {
        document.getElementById('plan-bandwidth-input').value > 0
          ? ''
          : validation.setFieldValue('bandwidth', '');
        document
          .getElementById('plan-bandwidth-input')
          .removeAttribute('disabled');
      } else if (event.target.name === 'speedScale') {
        document.getElementById('plan-speed-input').value > 0
          ? ''
          : validation.setFieldValue('speed', '');
        document.getElementById('plan-speed-input').removeAttribute('disabled');
      }
    }
  };

  async function findCityFromBroadband() {
    let result = await findCityByBroadbandId(planid);
    if(result && result.payload.length) {
      let newArr = [];
      result.payload.forEach((city) => {
        let newObj = {};
        newObj["label"] = city.name;
        newObj["value"] = city.id;
        newArr.push(newObj);
      })


      setFoundCities(newArr);
    }
  }

  useEffect(() => {
    if(planid) findCityFromBroadband();
    
  }, [])

  const attributeOptions = (opts, position) => {
    return broadbandAttribute
      .filter((attribute) => attribute.type == opts)
      .map((item, key) => ({
        value: item.id,
        BroadBandAttributeId: item.id,
        label: item.name,
        position: position,
        sortingOrder: key + 1,
      }));
  };

  const editAttributeOptions = (position) => {
    return broadbandPlan?.broadbandAttribute?.length
      ? broadbandPlan.broadbandAttribute
          .filter((attribute) => attribute.position == position)
          .map((item, key) => ({
            value: item.BroadBandAttributeId,
            BroadBandAttributeId: item.BroadBandAttributeId,
            label: item.name,
            position: position,
            sortingOrder: key + 1,
          }))
      : [];
  };

  const updateNewCityMapinDB = async (city) => {
    //update cities in DB 

    await updateCityByBroadbandId(city);
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (broadbandPlan && broadbandPlan.broadBand?.name) || '',
      description:
        (broadbandPlan && broadbandPlan.broadBand?.description) || '',
      sortingOrder:
        (broadbandPlan && broadbandPlan.broadBand?.sortingOrder) || '',
      sponsored: (broadbandPlan && broadbandPlan.broadBand?.sponsored) || '0',
      seoName: (broadbandPlan && broadbandPlan.broadBand?.seoName) || '',
      basePrice: (broadbandPlan && broadbandPlan.broadBand?.basePrice) || '',
      setupCost: (broadbandPlan && broadbandPlan.broadBand?.setupCost) || '',
      installation:
        (broadbandPlan && broadbandPlan.broadBand?.installation) || '',
      others: (broadbandPlan && broadbandPlan.broadBand?.others) || '',
      status: (broadbandPlan && broadbandPlan.broadBand?.status) || '',
      rating: (broadbandPlan && broadbandPlan.broadBand?.rating) || '',
      providerId: (broadbandPlan && broadbandPlan.broadBand?.providerId) || '',
      currency: (broadbandPlan && broadbandPlan.broadBand?.currency) || '',
      cost: (broadbandPlan && broadbandPlan.broadBand?.cost) || '',
      costDurationScale:
        (broadbandPlan && broadbandPlan.broadBand?.costDurationScale) || '',
      speed: (broadbandPlan && broadbandPlan.broadBand?.speed) || '',
      speedScale: (broadbandPlan && broadbandPlan.broadBand?.speedScale) || '',
      bandwidth: (broadbandPlan && broadbandPlan.broadBand?.bandwidth) || '',
      bandwidthSpeedScale:
        (broadbandPlan && broadbandPlan.broadBand?.bandwidthSpeedScale) || '',
      bandwidthDurationScale:
        (broadbandPlan && broadbandPlan.broadBand?.bandwidthDurationScale) ||
        '',
      contractLength:
        (broadbandPlan && broadbandPlan.broadBand?.contractLength) || '',
      contractLengthDuration:
        (broadbandPlan && broadbandPlan.broadBand?.contractLengthDuration) ||
        '',
      dealUrl: (broadbandPlan && broadbandPlan.broadBand?.dealUrl) || '',
      meta_title: (broadbandPlan && broadbandPlan.broadBand?.meta_title) || '',
      meta_keyword:
        (broadbandPlan && broadbandPlan.broadBand?.meta_keyword) || '',
      meta_description:
        (broadbandPlan && broadbandPlan.broadBand?.meta_description) || '',
      planExpiry: (broadbandPlan && broadbandPlan.broadBand?.planExpiry) || '',
      topAttributes: editAttributeOptions('TOP'),
      bottomAttributes: editAttributeOptions('BOTTOM'),
      ottAttributes: editAttributeOptions('OTT'),
      iconAttributes: editAttributeOptions('ICON'),
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a plan name'),
      status: Yup.string().required('Please select plan status'),
      rating: Yup.string().required('Please enter rating'),
      sortingOrder: Yup.string().required('Please enter sorting order'),
      providerId: Yup.string().required('Please select Plan Provider'),
      currency: Yup.string().required('Please select Currency'),
      cost: Yup.string().required('Please enter cost of the plan'),
      costDurationScale: Yup.string().required('Please select Cost Duration'),
      speed: Yup.string().required('Please enter speed for the plan'),
      speedScale: Yup.string().required('Please select Speed Scale'),
      bandwidth: Yup.string().required('Please enter bandwidth for the plan'),
      bandwidthSpeedScale: Yup.string().required(
        'Please select Bandwidth Speed Scale'
      ),
      bandwidthDurationScale: Yup.string().required(
        'Please select Bandwidth Duration Scale'
      ),
      basePrice: Yup.string().required('Please enter base price for the plan'),
      seoName: Yup.string().required('Please enter seo name for the plan'),
      meta_title: Yup.string()
        .max(
          100,
          'Max 100 characters allowed, though its best to have maximum 70 charaters in title'
        )
        .required('Please enter a meta title'),
      meta_keyword: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, keywords must not have more than 30 words'
        )
        .required('Please enter a meta keyword'),
      meta_description: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, though it best to have not more than 155 characters in description'
        )
        .required('Please enter a meta description'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateBroadbandPlan = {
          id: broadbandPlan ? broadbandPlan.broadBand.id : planid,
          name: values.name,
          description: values.description,
          sortingOrder: values.sortingOrder,
          basePrice: values.basePrice,
          seoName: values.seoName,
          setupCost: values.setupCost,
          installation: values.installation,
          others: values.others,
          sponsored: values.sponsored,
          status: values.status,
          rating: values.rating,
          providerId: values.providerId,
          currency: values.currency,
          cost: values.cost,
          costDurationScale: values.costDurationScale,
          speed: values.speed,
          speedScale: values.speedScale,
          bandwidth: values.bandwidth,
          bandwidthSpeedScale: values.bandwidthSpeedScale,
          bandwidthDurationScale: values.bandwidthDurationScale,
          contractLength: values.contractLength,
          contractLengthDuration: values.contractLengthDuration,
          dealUrl: values.dealUrl,
          meta_title: values.meta_title,
          meta_keyword: values.meta_keyword,
          meta_description: values.meta_description,
          planExpiry: values.planExpiry,
          arttibuteList: [
            ...values.topAttributes,
            ...values.bottomAttributes,
            ...values.ottAttributes,
            ...values.iconAttributes
          ],
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        dispatch(onUpdateBroadbandPlan(updateBroadbandPlan));
        history('/plans');
        validation.resetForm();
      } else {
        const newBroadbandPlan = {
          name: values['name'],
          description: values['description'],
          sortingOrder: values['sortingOrder'],
          status: values['status'],
          basePrice: values['basePrice'],
          seoName: values['seoName'],
          setupCost: values['setupCost'],
          installation: values['installation'],
          others: values['others'],
          sponsored: values['sponsored'],
          rating: values['rating'],
          providerId: values['providerId'],
          currency: values['currency'],
          cost: values['cost'],
          costDurationScale: values['costDurationScale'],
          speed: values['speed'],
          speedScale: values['speedScale'],
          bandwidth: values['bandwidth'],
          bandwidthSpeedScale: values['bandwidthSpeedScale'],
          bandwidthDurationScale: values['bandwidthDurationScale'],
          contractLength: values['contractLength'],
          contractLengthDuration: values['contractLengthDuration'],
          dealUrl: values['dealUrl'] ? `https://${values['dealUrl']}` : '',
          meta_title: values['meta_title'],
          meta_keyword: values['meta_keyword'],
          meta_description: values['meta_description'],
          planExpiry: values['planExpiry'],
          arttibuteList: [
            ...values['topAttributes'],
            ...values['bottomAttributes'],
            ...values['ottAttributes'],
            ...values['iconAttributes'],
          ],
          siteId: sessionStorage.getItem('siteid'),
          projectId: 3,
        };
        // save new plan
        dispatch(onAddNewBroadbandPlan(newBroadbandPlan));
        history('/plans');
        validation.resetForm();
      }
    },
  });

  const editorHandler = (event, editor) => {
    // Get the editor content
    const content = editor.getData();
  
    // Remove empty paragraphs
    //content = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
  
    // Set the field value with the updated content
    validation.setFieldValue('description', content);
  };
  

  useEffect(() => {
    if (cities && !isCitySuccess) {
      dispatch(onGetCities());
    }
  }, [dispatch, cities]);

  useEffect(() => {
    setCity(cities);
  }, [cities]);

  useEffect(() => {
    if (typeof planid != 'undefined' && !!planid) {
      const fetchedElem = broadbandPlans.find(
        (elem) => elem.broadBand?.id == planid
      );
      if (typeof fetchedElem != 'undefined') {
        setBroadbandPlan({ ...fetchedElem });
        setIsEdit(true);
      } else {
        history('/plans');
      }
    }
  }, [planid]);

  useEffect(() => {
    if (providers && !isProviderSuccess) {
      dispatch(onGetProviders());
    }
  }, [dispatch, providers]);

  useEffect(() => {
    setProvider(providers);
  }, [providers]);

  useEffect(() => {
    if (broadbandAttributes && !isBroadbandAttributeSuccess) {
      dispatch(onGetBroadbandAttributes());
    }
  }, [dispatch, broadbandAttributes]);

  useEffect(() => {
    setBroadbandAttribute(broadbandAttributes);
  }, [broadbandAttributes]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={!!isEdit ? 'Update BroadbandPlan' : 'Add BroadbandPlan'}
          pageTitle="Dashboard"
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {!!isEdit ? 'Plan Edit details' : 'Add Plan'}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-title-input"
                        >
                          Broadband Plan Name*
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="plan-title-input"
                          placeholder="Enter plan title"
                          name="name"
                          value={validation.values.name || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.name && validation.touched.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && validation.touched.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-dealurl-input"
                        >
                          Affiliate Link
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="plan-dealurl-input"
                          placeholder="Enter deal page url"
                          name="dealUrl"
                          // value={validation.values.dealUrl || ''}
                          value={`https://${validation.values.dealUrl.replace(/^(https?:\/\/)+/, '')}` || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                        />
                        

                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Label className="form-label" htmlFor="plan-status-input">
                        Plan Status*
                      </Label>
                      <Input
                        name="status"
                        type="select"
                        className="form-select"
                        id="plan-status-input"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ''}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        {StatusMenu.map((item, key) => (
                          <React.Fragment key={key}>
                            {item.options.map((item, key) => (
                              <option value={item.value} key={key}>
                                {item.label}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </Input>
                      {validation.errors.status && validation.touched.status ? (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="plan-sponsored-input"
                      >
                        Sponsored*
                      </Label>
                      <Input
                        name="sponsored"
                        type="select"
                        className="form-select"
                        id="plan-sponsored-input"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sponsored || '0'}
                        invalid={
                          validation.touched.sponsored &&
                          validation.errors.sponsored
                            ? true
                            : false
                        }
                      >
                        {BooleanStatusMenu.map((item, key) => (
                          <React.Fragment key={key}>
                            {item.options.map((item, key) => (
                              <option value={item.value} key={key}>
                                {item.label}
                              </option>
                            ))}
                          </React.Fragment>
                        ))}
                      </Input>
                      {validation.errors.sponsored &&
                      validation.touched.sponsored ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sponsored}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label htmlFor="sorting-field" className="form-label">
                        Sorting Order*
                      </Label>

                      <Input
                        name="sortingOrder"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="sorting-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sortingOrder || ''}
                        invalid={
                          validation.errors.sortingOrder &&
                          validation.touched.sortingOrder
                            ? true
                            : false
                        }
                      />
                      {validation.touched.sortingOrder &&
                      validation.errors.sortingOrder ? (
                        <FormFeedback type="invalid">
                          {validation.errors.sortingOrder}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col className="mb-3">
                      <Label htmlFor="rating-field" className="form-label">
                        Provider Rating*
                      </Label>

                      <Input
                        name="rating"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        id="rating-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.rating || ''}
                        invalid={
                          validation.errors.rating && validation.touched.rating
                            ? true
                            : false
                        }
                      />
                      {validation.touched.rating && validation.errors.rating ? (
                        <FormFeedback type="invalid">
                          {validation.errors.rating}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-speed-input"
                        >
                          Plan Speed*
                        </Label>
                        <Input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          id="plan-speed-input"
                          placeholder="Enter speed"
                          name="speed"
                          value={validation.values.speed || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.speed && validation.touched.speed
                              ? true
                              : false
                          }
                        />
                      </div>
                      {validation.errors.speed && validation.touched.speed ? (
                        <FormFeedback type="invalid">
                          {validation.errors.speed}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-speedScale-input"
                        >
                          Plan Speed Scale*
                        </Label>
                        <Input
                          name="speedScale"
                          type="select"
                          className="form-select"
                          id="plan-speedScale-input"
                          onChange={handleUnlimited}
                          onBlur={validation.handleBlur}
                          value={validation.values.speedScale || ''}
                          invalid={
                            validation.touched.speedScale &&
                            validation.errors.speedScale
                              ? true
                              : false
                          }
                        >
                          {DataLimitScale.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.errors.speedScale &&
                        validation.touched.speedScale ? (
                          <FormFeedback type="invalid">
                            {validation.errors.speedScale}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-contractLength-input"
                        >
                          Contract Length
                        </Label>
                        <Input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          id="plan-contractLength-input"
                          placeholder="Enter Contract Length"
                          name="contractLength"
                          value={validation.values.contractLength || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-contractLengthDuration-input"
                        >
                          Contract Length Duration
                        </Label>
                        <Input
                          name="contractLengthDuration"
                          type="select"
                          className="form-select"
                          id="plan-contractLengthDuration-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contractLengthDuration || ''}
                        >
                          {DurationScale.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-bandwidth-input"
                        >
                          Bandwidth*
                        </Label>
                        <Input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          id="plan-bandwidth-input"
                          placeholder="Enter Bandwidth "
                          name="bandwidth"
                          value={validation.values.bandwidth || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.bandwidth &&
                            validation.touched.bandwidth
                              ? true
                              : false
                          }
                        />
                        {validation.errors.bandwidth &&
                        validation.touched.bandwidth ? (
                          <FormFeedback type="invalid">
                            {validation.errors.bandwidth}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-bandwidthSpeedScale-input"
                        >
                          Bandwidth Speed Scale*
                        </Label>
                        <Input
                          type="select"
                          className="form-control"
                          id="plan-bandwidthSpeedScale-input"
                          placeholder="Select Bandwidth Speed Scale "
                          name="bandwidthSpeedScale"
                          value={validation.values.bandwidthSpeedScale || ''}
                          onBlur={validation.handleBlur}
                          onChange={handleUnlimited}
                          invalid={
                            validation.errors.bandwidthSpeedScale &&
                            validation.touched.bandwidthSpeedScale
                              ? true
                              : false
                          }
                        >
                          {DataLimitScale.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.errors.bandwidthSpeedScale &&
                        validation.touched.bandwidthSpeedScale ? (
                          <FormFeedback type="invalid">
                            {validation.errors.bandwidthSpeedScale}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-bandwidthDurationScale-input"
                        >
                          Bandwidth Duation Scale*
                        </Label>
                        <Input
                          name="bandwidthDurationScale"
                          type="select"
                          className="form-select"
                          id="plan-bandwidthDurationScale-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.bandwidthDurationScale || ''}
                          invalid={
                            validation.touched.bandwidthDurationScale &&
                            validation.errors.bandwidthDurationScale
                              ? true
                              : false
                          }
                        >
                          {DurationScale.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.errors.bandwidthDurationScale &&
                        validation.touched.bandwidthDurationScale ? (
                          <FormFeedback type="invalid">
                            {validation.errors.bandwidthDurationScale}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-currency-input"
                        >
                          Currency*
                        </Label>
                        <Input
                          type="select"
                          className="form-control"
                          id="plan-currency-input"
                          placeholder="Enter currency "
                          name="currency"
                          value={validation.values.currency || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.currency &&
                            validation.touched.currency
                              ? true
                              : false
                          }
                        >
                          {CurrencyMenu.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.errors.currency &&
                        validation.touched.currency ? (
                          <FormFeedback type="invalid">
                            {validation.errors.currency}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="plan-cost-input">
                          Plan Cost*
                        </Label>
                        <Input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          id="plan-cost-input"
                          placeholder="Enter cost "
                          name="cost"
                          value={validation.values.cost || ''}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.cost && validation.touched.cost
                              ? true
                              : false
                          }
                        />
                        {validation.errors.cost && validation.touched.cost ? (
                          <FormFeedback type="invalid">
                            {validation.errors.cost}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="plan-costDuration-input"
                        >
                          Plan Cost Duation*
                        </Label>
                        <Input
                          name="costDurationScale"
                          type="select"
                          className="form-select"
                          id="plan-costDuration-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.costDurationScale || ''}
                          invalid={
                            validation.touched.costDurationScale &&
                            validation.errors.costDurationScale
                              ? true
                              : false
                          }
                        >
                          {DurationScale.map((item, key) => (
                            <React.Fragment key={key}>
                              {item.options.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.label}
                                </option>
                              ))}
                            </React.Fragment>
                          ))}
                        </Input>
                        {validation.errors.costDurationScale &&
                        validation.touched.costDurationScale ? (
                          <FormFeedback type="invalid">
                            {validation.errors.costDurationScale}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Plan Description</Label>
                      <CKEditor
                        editor={Editor}
                        data={validation.values.description || ''}
                        onChange={editorHandler}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Select Broadband Attributes
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="mb-3">
                      {broadbandAttribute && broadbandAttribute.length > 0 ? (
                        <div className="mb-3">
                          <Label className="form-label">
                            Top Broadband Attributes
                          </Label>

                          <Select
                            name="topAttributes"
                            value={validation.values.topAttributes}
                            isMulti={true}
                            isClearable={true}
                            onChange={(selectedOptions) =>
                              validation.setFieldValue(
                                'topAttributes',
                                selectedOptions
                              )
                            }
                            options={attributeOptions('btn', 'TOP')}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Row>
                  <Row>
                    <div className="mb-3">
                      {broadbandAttribute && broadbandAttribute.length > 0 ? (
                        <div className="mb-3">
                          <Label className="form-label">
                            Bottom Broadband Attributes
                          </Label>
                          <Select
                            name="bottomAttributes"
                            value={validation.values.bottomAttributes}
                            isMulti={true}
                            isClearable={true}
                            onChange={(selectedOptions) =>
                              validation.setFieldValue(
                                'bottomAttributes',
                                selectedOptions
                              )
                            }
                            options={attributeOptions('btn', 'BOTTOM')}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        {broadbandAttribute && broadbandAttribute.length > 0 ? (
                          <div className="mb-3">
                            <Label className="form-label">
                              Icon Broadband Attributes
                            </Label>
                            <Select
                              name="iconAttributes"
                              value={validation.values.iconAttributes}
                              isMulti={true}
                              isClearable={true}
                              onChange={(selectedOptions) =>
                                validation.setFieldValue(
                                  'iconAttributes',
                                  selectedOptions
                                )
                              }
                              options={attributeOptions('icon', 'ICON')}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        {broadbandAttribute && broadbandAttribute.length > 0 ? (
                          <div className="mb-3">
                            <Label className="form-label">
                              OTT Broadband Attributes
                            </Label>
                            <Select
                              name="ottAttributes"
                              value={validation.values.ottAttributes}
                              isMulti={true}
                              isClearable={true}
                              onChange={(selectedOptions) =>
                                validation.setFieldValue(
                                  'ottAttributes',
                                  selectedOptions
                                )
                              }
                              options={attributeOptions('ott', 'OTT')}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button type="submit" className="btn btn-success w-sm">
                  {!!isEdit ? 'Update' : 'Submit'}
                </button>
              </div>
            </Col>
            
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Provider List</h5>
                </CardHeader>
                <CardBody>
                  {providers && providers.length > 0 ? (
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="plan-providerId-input"
                      >
                        Select Provider*
                      </Label>
                      <Input
                        name="providerId"
                        type="select"
                        className="form-select"
                        id="plan-providerId-input"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.providerId || ''}
                        invalid={
                          validation.touched.providerId &&
                          validation.errors.providerId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {providers.map((item, key) => (
                          <React.Fragment key={key}>
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          </React.Fragment>
                        ))}
                      </Input>
                      {validation.errors.providerId &&
                      validation.touched.providerId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.providerId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : (
                    ''
                  )}
                </CardBody>
              </Card>
              {/* City to City Card */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">City Mapping</h5>
                </CardHeader>
                <CardBody>
                <div className="mb-3">
                    {city && city.length > 0 ? (
                      <div className="mb-3">
                        <Label className="form-label">Select Cities</Label>

                        <Select
                          name="cityMapping"
                          value={foundCities}
                          isMulti={true}
                          isClearable={true}
                          onChange={async (selectedOptions, event) => {
                            if(event.action == "remove-value") {
                              await deleteCityByBroadbandId({
                                data: {
                                  cityId: event.removedValue.value.toString(),
                                  broadbandId: planid
                                }
                              })
                              setFoundCities(selectedOptions)
                              return;
                            }

                            if(event.action == "select-option") {
                              await updateNewCityMapinDB({
                                cityId: event.option.value.toString(),
                                broadbandId: planid
                              });
                              setFoundCities(selectedOptions)
                              return;
                            }

                            
                          }}
                          options={city.map((item, index) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </CardBody>
              </Card>
              {/* City to City Card */}
              {/* Card of Seo Name */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">SEO Name</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="seoName-field" className="form-label">
                      SEO Name
                    </Label>
                    <Input
                      name="seoName"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="seoName-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoName || ''}
                      invalid={
                        validation.errors.seoName &&
                        validation.touched.seoName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.seoName &&
                    validation.errors.seoName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.seoName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              {/* Card of Seo Name */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Price Breakdown</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="basePrice-field" className="form-label">
                      Base Price
                    </Label>

                    <Input
                      name="basePrice"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="basePrice-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.basePrice || ''}
                      invalid={
                        validation.errors.basePrice &&
                        validation.touched.basePrice
                          ? true
                          : false
                      }
                    />
                    {validation.touched.basePrice &&
                    validation.errors.basePrice ? (
                      <FormFeedback type="invalid">
                        {validation.errors.basePrice}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="installation-field" className="form-label">
                      Installation
                    </Label>

                    <Input
                      name="installation"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="installation-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.installation || ''}
                      invalid={
                        validation.errors.installation &&
                        validation.touched.installation
                          ? true
                          : false
                      }
                    />
                    {validation.touched.installation &&
                    validation.errors.installation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.installation}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="setupCost-field" className="form-label">
                      Setup Cost
                    </Label>

                    <Input
                      name="setupCost"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="setupCost-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.setupCost || ''}
                      invalid={
                        validation.errors.setupCost &&
                        validation.touched.setupCost
                          ? true
                          : false
                      }
                    />
                    {validation.touched.setupCost &&
                    validation.errors.setupCost ? (
                      <FormFeedback type="invalid">
                        {validation.errors.setupCost}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="others-field" className="form-label">
                      Security Deposit
                    </Label>

                    <Input
                      name="others"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="others-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.others || ''}
                      invalid={
                        validation.errors.others && validation.touched.others
                          ? true
                          : false
                      }
                    />
                    {validation.touched.others && validation.errors.others ? (
                      <FormFeedback type="invalid">
                        {validation.errors.others}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Meta Data</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="meta-title-input">
                      Meta title
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter meta title"
                      id="meta-title-input"
                      name="meta_title"
                      value={validation.values.meta_title || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_title &&
                        validation.touched.meta_title
                          ? true
                          : false
                      }
                    />
                    {validation.errors.meta_title &&
                    validation.touched.meta_title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_title}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="meta-keywords-input">
                      Meta Keywords
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter meta keywords"
                      id="meta-keywords-input"
                      name="meta_keyword"
                      value={validation.values.meta_keyword || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_keyword &&
                        validation.touched.meta_keyword
                          ? true
                          : false
                      }
                    />
                    {validation.errors.meta_keyword &&
                    validation.touched.meta_keyword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_keyword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label
                      className="form-label"
                      htmlFor="meta-description-input"
                    >
                      Meta Description
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="meta-description-input"
                      placeholder="Enter meta description"
                      name="meta_description"
                      rows="3"
                      value={validation.values.meta_description || ''}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.meta_description &&
                        validation.touched.meta_description
                          ? true
                          : false
                      }
                    ></Input>
                    {validation.errors.meta_description &&
                    validation.touched.meta_description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.meta_description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddBroadbandPlans;
