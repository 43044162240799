import { createSlice } from '@reduxjs/toolkit';
import { getCities, addNewCity, updateCity } from './thunk';
export const initialState = {
  cities: [],
  error: {},
};

const CitiesSlice = createSlice({
  name: 'CitiesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.cities = action.payload.payload;
      state.isCityCreated = false;
      state.isCitySuccess = true;
    });

    builder.addCase(getCities.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isCityCreated = false;
      state.isCitySuccess = false;
    });

    builder.addCase(addNewCity.fulfilled, (state, action) => {
      state.cities.push(action.payload.payload);
      state.isCityCreated = true;
    });
    builder.addCase(addNewCity.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateCity.fulfilled, (state, action) => {
      state.cities = state.cities.map((city) =>
        city.id.toString() === action.payload.payload.id.toString()
          ? { ...city, ...action.payload.payload }
          : city
      );
    });
    builder.addCase(updateCity.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default CitiesSlice.reducer;
