import { createSlice } from '@reduxjs/toolkit';
import { getDomains, addNewDomain, updateDomain } from './thunk';
export const initialState = {
  domains: [],
  error: {},
};

const DomainsSlice = createSlice({
  name: 'DomainsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getDomains.fulfilled, (state, action) => {
      state.domains = action.payload.payload;
      state.isDomainCreated = false;
      state.isDomainSuccess = true;
    });

    builder.addCase(getDomains.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isDomainCreated = false;
      state.isDomainSuccess = false;
    });

    builder.addCase(addNewDomain.fulfilled, (state, action) => {
      state.domains.push(action.payload.payload);
      state.isDomainCreated = true;
    });
    builder.addCase(addNewDomain.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateDomain.fulfilled, (state, action) => {
      state.domains = state.domains.map((domain) =>
        domain.id.toString() === action.payload.payload.id.toString()
          ? { ...domain, ...action.payload.payload }
          : domain
      );
    });
    builder.addCase(updateDomain.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default DomainsSlice.reducer;
