import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
  getProviders as onGetProviders,
  getDomains as onGetDomains,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';
import { Rating } from '../../Components/Common/Utils';

const Providers = () => {
  const dispatch = useDispatch();
  const {
    domains,
    isDomainSuccess,
    providers,
    isProviderCreated,
    isProviderSuccess,
    error,
  } = useSelector((state) => ({
    domains: state.Domains.domains,
    isDomainSuccess: state.Domains.isDomainSuccess,
    providers: state.Providers.providers,
    isProviderCreated: state.Providers.isProviderCreated,
    isProviderSuccess: state.Providers.isProviderSuccess,
    error: state.Providers.error,
  }));

  const [provider, setProvider] = useState([]);
  const [domain, setDomain] = useState([]);

  const getDomainUrl = () => {
    return (
      'https://' +
      domains.find((item) => item.id == sessionStorage.getItem('siteid'))
        .domainName
    );
  };

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    setDomain(domains);
  }, [domains]);

  useEffect(() => {
    if (providers && !isProviderSuccess) {
      dispatch(onGetProviders());
    }
  }, [dispatch, providers]);

  useEffect(() => {
    setProvider(providers);
  }, [providers]);

  useEffect(() => {
    if (isProviderCreated) {
      setProvider(null);
      dispatch(onGetProviders());
    }
  }, [dispatch, isProviderCreated]);

  // Providers Column
  const columns = useMemo(() => [
    {
      Header: 'Provider',
      accessor: 'name',
      filterable: true,
      Cell: (product) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <img
                  src={product.row.original.logo || ''}
                  alt=""
                  className="img-fluid d-block"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <span className="text-dark">{product.row.original.name}</span>
              </h5>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: 'Rating',
      accessor: 'rating',
      filterable: false,
      Cell: (cellProps) => {
        return <Rating {...cellProps} />;
      },
    },
    {
      Header: 'Sorting Order',
      accessor: 'sortingOrder',
      filterable: true,
    },
    {
      Header: 'Affiliate Link',
      accessor: 'link',
      filterable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      filterable: true,
      Cell: (cell) => {
        switch (cell.value) {
          case '1':
          case 1:
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case '0':
          case 0:
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Block
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                {cell.value}
              </span>
            );
        }
      },
    },
    {
      Header: 'Action',
      Cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item edit" title="Edit">
              <Link
                to={'/providers/edit/' + cellProps.row.original.id}
                className="text-muted d-inline-block edit-item-btn"
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                Edit
              </Link>
            </li>
            <li className="list-inline-item edit" title="View">
              <Link
                target="_blank"
                to={
                  getDomainUrl() +
                  '/broadband-plan/' +
                  cellProps.row.original.seoName +
                  '/' 
                  //cellProps.row.original.id
                }
                className="text-muted d-inline-block edit-item-btn"
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{' '}
                View
              </Link>
            </li>
          </ul>
        );
      },
    },
  ]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Providers | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={providers}
        />
        <Container fluid>
          <BreadCrumb title="Providers" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="providerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Provider List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto ms-auto">
                      <div>
                        <Link to="/providers/add" className="btn btn-success">
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Provider
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isProviderSuccess ? (
                      <>
                        {providers.length ? (
                          <TableContainer
                            columns={columns}
                            data={providers || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for provider..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Providers;
