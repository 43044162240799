import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import LoginReducer from './auth/login/reducer';
import ProfileReducer from './auth/profile/reducer';
import DomainsReducer from './domains/reducer';
import CountriesReducer from './countries/reducer';
import CitiesReducer from './cities/reducer';
import KeysReducer from './keys/reducer';
import BroadbandAttributesReducer from './broadbandattributes/reducer';
import ProvidersReducer from './providers/reducer';
import BroadbandPlansReducer from './broadbandplans/reducer';
import LeadsReducer from './leads/reducer';
import ReportAbusesReducer from './reportabuses/reducer';
import ReviewsReducer from './reviews/reducer';
import ManageWidgetsReducer from './manageWidgets/reducer';
import ManageWidgetCardsReducer from './manageWidgetCards/reducer';

import ProvidersWidgetsReducer from './providersWidgets/reducer';

// Countries

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  Domains: DomainsReducer,
  Countries: CountriesReducer,
  Cities: CitiesReducer,
  Keys: KeysReducer,
  BroadbandAttributes: BroadbandAttributesReducer,
  Providers: ProvidersReducer,
  BroadbandPlans: BroadbandPlansReducer,
  Leads: LeadsReducer,
  ReportAbuses: ReportAbusesReducer,
  Reviews: ReviewsReducer,
  ManageWidgets: ManageWidgetsReducer,
  ManageWidgetCards: ManageWidgetCardsReducer,
  ProvidersWidgets: ProvidersWidgetsReducer,
  
});

export default rootReducer;
