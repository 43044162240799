import { createSlice } from '@reduxjs/toolkit';
import {
  getBroadbandAttributes,
  addNewBroadbandAttribute,
  updateBroadbandAttribute,
} from './thunk';
export const initialState = {
  broadbandAttributes: [],
  error: {},
};

const BroadbandAttributesSlice = createSlice({
  name: 'BroadbandAttributesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getBroadbandAttributes.fulfilled, (state, action) => {
      state.broadbandAttributes = action.payload.payload;
      state.isBroadbandAttributeCreated = false;
      state.isBroadbandAttributeSuccess = true;
    });

    builder.addCase(getBroadbandAttributes.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isBroadbandAttributeCreated = false;
      state.isBroadbandAttributeSuccess = false;
    });

    builder.addCase(addNewBroadbandAttribute.fulfilled, (state, action) => {
      state.broadbandAttributes.push(action.payload.payload);
      state.isBroadbandAttributeCreated = true;
    });
    builder.addCase(addNewBroadbandAttribute.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateBroadbandAttribute.fulfilled, (state, action) => {
      state.broadbandAttributes = state.broadbandAttributes.map(
        (broadbandAttribute) =>
          broadbandAttribute.id.toString() ===
          action.payload.payload.id.toString()
            ? { ...broadbandAttribute, ...action.payload.payload }
            : broadbandAttribute
      );
    });
    builder.addCase(updateBroadbandAttribute.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default BroadbandAttributesSlice.reducer;
