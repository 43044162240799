import { createSlice } from '@reduxjs/toolkit';
import { getLeads } from './thunk';
export const initialState = {
  leads: [],
  error: {},
};

const LeadsSlice = createSlice({
  name: 'LeadsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.leads = action.payload.payload;
      state.isLeadCreated = false;
      state.isLeadSuccess = true;
    });

    builder.addCase(getLeads.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isLeadCreated = false;
      state.isLeadSuccess = false;
    });
  },
});

export default LeadsSlice.reducer;
