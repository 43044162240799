import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getCities as getCitiesApi,
  addNewCity as addNewCityApi,
  updateCity as updateCityApi,
} from '../../helpers/backend_helper';

export const getCities = createAsyncThunk('getCities', async () => {
  try {
    const response = getCitiesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateCity = createAsyncThunk('updateCity', async (city) => {
  try {
    const response = updateCityApi(city);
    const data = await response;
    toast.success('City Updateded Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('City Updateded Failed', { autoClose: 3000 });
    return error;
  }
});

export const addNewCity = createAsyncThunk('addNewCity', async (city) => {
  try {
    const response = addNewCityApi(city);
    const data = await response;
    toast.success('City Added Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('City Added Failed', { autoClose: 3000 });
    return error;
  }
});
