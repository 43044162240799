import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';

import {
  getKeys as onGetKeys,
  updateKey as onUpdateKey,
} from '../../slices/thunks';

// Redux
import { useSelector, useDispatch } from 'react-redux';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

const ManageTopEntertainmentPlan = () => {
  const keyid = 'topentertainmentplan';
  document.title = 'Manage Top Entertainment Plan | Admin & Dashboard';

  const dispatch = useDispatch();
  const {
    topentertainmentplan,
    istopentertainmentplanCreated,
    istopentertainmentplanSuccess,
    error,
  } = useSelector((state) => ({
    topentertainmentplan: state.Keys.topentertainmentplan,
    istopentertainmentplanCreated: state.Keys.istopentertainmentplanCreated,
    istopentertainmentplanSuccess: state.Keys.istopentertainmentplanSuccess,
    error: state.Keys.errortopentertainmentplan,
  }));

  const [key, setKey] = useState([]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      tagline: (key && key.tagline) || '',
      headline: (key && key.headline) || '',
      desc: (key && key.desc) || '',
      meta_title: (key && key.meta_title) || '',
      meta_keyword: (key && key.meta_keyword) || '',
      meta_description: (key && key.meta_description) || '',
    },
    validationSchema: Yup.object({
      tagline: Yup.string().required('Please enter tagline text'),
      headline: Yup.string().required('Please enter headline text'),
      desc: Yup.string().required('Please enter descripttion text'),
      meta_title: Yup.string()
        .max(
          100,
          'Max 100 characters allowed, though its best to have maximum 70 charaters in title'
        )
        .required('Please enter a meta title'),
      meta_keyword: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, keywords must not have more than 30 words'
        )
        .required('Please enter a meta keyword'),
      meta_description: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, though it best to have not more than 155 characters in description'
        )
        .required('Please enter a meta description'),
    }),
    onSubmit: async (values) => {
      const value = {
        tagline: values.tagline,
        headline: values.headline,
        desc: values.desc,
        meta_title: values.meta_title,
        meta_keyword: values.meta_keyword,
        meta_description: values.meta_description,
      };
      const updateKey = {
        projectId: 3,
        keyName: keyid,
        value: JSON.stringify(value),
      };
      // update Key
      dispatch(onUpdateKey(updateKey));
      validation.resetForm();
    },
  });

  useEffect(() => {
    if (topentertainmentplan && !istopentertainmentplanSuccess) {
      dispatch(onGetKeys(keyid));
    }
  }, [dispatch, topentertainmentplan]);

  useEffect(() => {
    setKey(topentertainmentplan);
  }, [topentertainmentplan]);

  useEffect(() => {
    if (istopentertainmentplanCreated) {
      setKey(null);
      dispatch(onGetKeys(keyid));
    }
  }, [dispatch, istopentertainmentplanCreated]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Manage Top Entertainment Plan"
          pageTitle="Dashboard"
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              {istopentertainmentplanSuccess ? (
                <>
                  <Card>
                    <CardBody>
                      <Row>
                        <div className="col-5">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="key-tagline-input"
                            >
                              Tagline
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="key-tagline-input"
                              placeholder="Enter Tagline"
                              name="tagline"
                              value={validation.values.tagline || ''}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.tagline &&
                                validation.touched.tagline
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.tagline &&
                            validation.touched.tagline ? (
                              <FormFeedback type="invalid">
                                {validation.errors.tagline}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="key-headline-input"
                            >
                              Headline
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="key-headline-input"
                              placeholder="Enter Headline"
                              name="headline"
                              value={validation.values.headline || ''}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.headline &&
                                validation.touched.headline
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.headline &&
                            validation.touched.headline ? (
                              <FormFeedback type="invalid">
                                {validation.errors.headline}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="key-desc-input"
                            >
                              Description
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="key-desc-input"
                              placeholder="Enter Description"
                              name="desc"
                              value={validation.values.desc || ''}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.desc &&
                                validation.touched.desc
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.desc &&
                            validation.touched.desc ? (
                              <FormFeedback type="invalid">
                                {validation.errors.desc}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="meta-title-input"
                          >
                            Meta title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter meta title"
                            id="meta-title-input"
                            name="meta_title"
                            value={validation.values.meta_title || ''}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.meta_title &&
                              validation.touched.meta_title
                                ? true
                                : false
                            }
                          />
                          {validation.errors.meta_title &&
                          validation.touched.meta_title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.meta_title}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="meta-keywords-input"
                          >
                            Meta Keywords
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter meta keywords"
                            id="meta-keywords-input"
                            name="meta_keyword"
                            value={validation.values.meta_keyword || ''}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.meta_keyword &&
                              validation.touched.meta_keyword
                                ? true
                                : false
                            }
                          />
                          {validation.errors.meta_keyword &&
                          validation.touched.meta_keyword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.meta_keyword}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div>
                          <Label
                            className="form-label"
                            htmlFor="meta-description-input"
                          >
                            Meta Description
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="meta-description-input"
                            placeholder="Enter meta description"
                            name="meta_description"
                            rows="3"
                            value={validation.values.meta_description || ''}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.meta_description &&
                              validation.touched.meta_description
                                ? true
                                : false
                            }
                          ></Input>
                          {validation.errors.meta_description &&
                          validation.touched.meta_description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.meta_description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="text-end mb-3">
                    <button type="submit" className="btn btn-success w-sm">
                      Submit
                    </button>
                  </div>
                </>
              ) : (
                <Loader error={error} />
              )}
              <ToastContainer closeButton={false} limit={1} />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default ManageTopEntertainmentPlan;
