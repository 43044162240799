import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import PreviewImage from '../../Components/Common/PreviewImage';
import axios from 'axios';

import {
  getManageWidgetCards as onGetManageWidgetCards,
  addNewManageWidgetCard as onAddNewManageWidgetCard,
  updateManageWidgetCard as onUpdateManageWidgetCard,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

const ManageWidgetCards = () => {
  const { wizardid } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  let {
    manageWidgetCards,
    isManageWidgetCardCreated,
    isManageWidgetCardSuccess,
    error,
  } = useSelector((state) => ({
    manageWidgetCards: state.ManageWidgetCards.manageWidgetCards,
    isManageWidgetCardCreated:
      state.ManageWidgetCards.isManageWidgetCardCreated,
    isManageWidgetCardSuccess:
      state.ManageWidgetCards.isManageWidgetCardSuccess,
    error: state.ManageWidgetCards.error,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [manageWidgetCard, setManageWidgetCard] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setManageWidgetCard([]);
    } else {
      setModal(true);
    }
  }, [modal]);

  const imageHandler = (event) => {
    validation.setFieldValue('image', event.target.files[0]);
  };

  const uploadToCloudinary = async (file) => {
    const img_file = file,
      formData = new FormData();
    formData.append('file', img_file);
    formData.append('upload_preset', 'shooglo');
    const uninterceptedAxiosInstance = axios.create();
    var res = await uninterceptedAxiosInstance.post(
      'https://api.cloudinary.com/v1_1/dxzr7l0pe/image/upload',
      formData
    );
    return res.data.secure_url;
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (manageWidgetCard && manageWidgetCard.title) || '',
      description: (manageWidgetCard && manageWidgetCard.description) || '',
      image: (manageWidgetCard && manageWidgetCard.image) || '',
      sortingOrder: (manageWidgetCard && manageWidgetCard.sortingOrder) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter title'),
      description: Yup.string().required('Please enter description'),
      sortingOrder: Yup.string().required('Please enter sorting order'),
    }),
    onSubmit: async (values) => {
      let imageUrl = values.image;

      if (
        typeof imageUrl?.name != 'undefined' &&
        typeof imageUrl?.name == 'string'
      ) {
        imageUrl = await uploadToCloudinary(imageUrl);
      }

      if (isEdit) {
        const updateManageWidgetCard = {
          id: manageWidgetCard ? manageWidgetCard.id : 0,
          title: values.title,
          description: values.description,
          wizardId: wizardid,
          image: imageUrl,
          sortingOrder: values.sortingOrder,
          projectId: 2,
          siteId: sessionStorage.getItem('siteid'),
        };
        // update manageWidgetCard
        dispatch(onUpdateManageWidgetCard(updateManageWidgetCard));
        validation.resetForm();
      } else {
        const newManageWidgetCard = {
          title: values['title'],
          description: values['description'],
          wizardId: wizardid,
          image: imageUrl,
          sortingOrder: values['sortingOrder'],
          projectId: 2,
          siteId: sessionStorage.getItem('siteid'),
        };
        // save new manageWidgetCard
        dispatch(onAddNewManageWidgetCard(newManageWidgetCard));
        validation.resetForm();
      }
      toggle();
    },
  });
  // Update Data
  const handleManageWidgetCardClick = useCallback(
    (arg) => {
      const manageWidgetCard = arg;
      setManageWidgetCard({
        id: manageWidgetCard.id,
        title: manageWidgetCard.title || '',
        description: manageWidgetCard.description || '',
        image: manageWidgetCard.image || '',
        sortingOrder: manageWidgetCard.sortingOrder || '',
        wizardId: wizardid,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    isManageWidgetCardSuccess = false;
  }, [location]);

  useEffect(() => {
    if (manageWidgetCards && !isManageWidgetCardSuccess) {
      dispatch(onGetManageWidgetCards(wizardid));
    }
  }, [dispatch, manageWidgetCards]);

  useEffect(() => {
    setManageWidgetCard(manageWidgetCards);
  }, [manageWidgetCards]);

  useEffect(() => {
    if (!isEmpty(manageWidgetCards)) {
      setManageWidgetCard(manageWidgetCards);
      setIsEdit(false);
    }
  }, [manageWidgetCards]);

  // Add Data
  const handleManageWidgetCardClicks = () => {
    setManageWidgetCard([]);
    setIsEdit(false);
    toggle();
  };

  useEffect(() => {
    if (isManageWidgetCardCreated) {
      setManageWidgetCard(null);
      dispatch(onGetManageWidgetCards(wizardid));
    }
  }, [dispatch, isManageWidgetCardCreated]);

  // ManageWidgetCards Column
  const columns = useMemo(
    () => [
      {
        Header: 'Widget',
        accessor: 'title',
        filterable: false,
        Cell: (item) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={item.row.original.image || ''}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <span className="text-dark">{item.row.original.title}</span>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        filterable: false,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-muted d-inline-block"
                  onClick={() => {
                    const manageWidgetCardData = cellProps.row.original;
                    handleManageWidgetCardClick(manageWidgetCardData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i> Edit
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleManageWidgetCardClick]
  );

  document.title = 'Widget Items | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Widget Items" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="manageWidgetCardList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Widget Items List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Widget Item
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isManageWidgetCardSuccess ? (
                      <>
                        {manageWidgetCards.length ? (
                          <TableContainer
                            columns={columns}
                            data={manageWidgetCards || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            handleManageWidgetCardClick={
                              handleManageWidgetCardClicks
                            }
                            divClass="table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for Widget Cards..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? 'Edit Widget' : 'Add Widget'}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <div className="mb-3">
                          <Label htmlFor="title-field" className="form-label">
                            Title
                          </Label>
                          <Input
                            name="title"
                            id="title-field"
                            className="form-control"
                            placeholder="Widget Item Title"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ''}
                            invalid={
                              validation.touched.title &&
                              validation.errors.title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="sortingOrder-field"
                            className="form-label"
                          >
                            Sorting Order
                          </Label>

                          <Input
                            name="sortingOrder"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            placeholder="1"
                            id="sortingOrder-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sortingOrder || ''}
                            invalid={
                              validation.errors.sortingOrder &&
                              validation.touched.sortingOrder
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sortingOrder &&
                          validation.errors.sortingOrder ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sortingOrder}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="description">
                            Description
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="description"
                            placeholder="Widget Item description"
                            name="description"
                            rows="5"
                            value={validation.values.description || ''}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.description &&
                              validation.touched.description
                                ? true
                                : false
                            }
                          ></Input>
                          {validation.errors.description &&
                          validation.touched.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <h5 className="fs-15 mb-1">Banner Image</h5>
                          <p className="text-muted">
                            800 X 500 preferable dimensions
                          </p>
                          <div>
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="product-image-input"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Select Image"
                                  data-bs-original-title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  id="product-image-input"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={imageHandler}
                                />
                              </div>
                              <div className="plan-main-img">
                                {validation.values.image &&
                                validation.values.image.name ? (
                                  <PreviewImage
                                    file={validation.values.image}
                                  />
                                ) : (
                                  <div className="avatar-title bg-light rounded">
                                    <img
                                      src={validation.values.image || ''}
                                      alt=""
                                      className="h-auto"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {validation.errors.image &&
                          validation.touched.image ? (
                            <FormFeedback type="invalid">
                              {validation.errors.image}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {!!isEdit
                              ? 'Update Widget Item'
                              : 'Add Widget Item'}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageWidgetCards;
