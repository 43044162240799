import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from 'react-redux';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import TableContainer from '../../Components/Common/TableContainer';

import {
    getManageWidgets as onGetManageWidgets,
    getDomains as onGetDomainsApi,
    getCities as onGetCities,
    // getProviders as onGetProviders
} from '../../slices/thunks';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const ManageWidgets = () => {
    const dispatch = useDispatch();
    
    const [manageWidget, setManageWidget] = useState([]);
    const [domain, setDomain] = useState([]);
    const [manageMenu, setManageMenu] = useState([]);
    const [city, setCity] = useState([]);
    // const [provider, setProvider] = useState([]);

    // console.log("setProvider", provider)

    const { 
        manageWidgets, 
        isManageWidgetSuccess, 
        domains,
        isDomainSuccess,
        cities,
        // providers,
        isCitySuccess,
        // isProviderSuccess,
        error 
    } = useSelector((state) => ({
        manageWidgets: state.ManageWidgets.manageWidgets,
        isManageWidgetSuccess: state.ManageWidgets.isManageWidgetSuccess,
        domains: state.Domains.domains,
        cities: state.Cities.cities,
        isCitySuccess: state.Cities.isCitySuccess,
        // providers: state.Providers.providers,
        // isProviderSuccess: state.Providers.isProviderSuccess,
        isDomainSuccess: state.Domains.isDomainSuccess,
        error: state.ManageWidgets.error,
    }));

    const getPageCountry = (countryId) => {
        return domain.filter((item) => item.id == countryId)[0]?.websiteName;
    };

    const getPageCity = (countryId, cityId) => {
        if (cityId === 0) {
            return 'Country Page';
        } else if (cityId === -1) {
            const allCities = city.map((elem) => elem.name).join(', ');
            return allCities;
        } else {
            const cityDetails = city.find((elem) => elem.id === cityId);
            return cityDetails ? cityDetails.name : '-';
        }
    };

    // const getPageProvider = (countryId, providerId) => {
    //     if (providerId === 0) {
    //         return 'Country Page';
    //     } else if (providerId === -1) {
    //         const allProviders = provider.map((elem) => elem.name).join(', ');
    //         return allProviders;
    //     } else {
    //         const providerDetails = provider.find((elem) => elem.id === providerId);
    //         return providerDetails ? providerDetails.name : '-';
    //     }
    // };
    

    // ManageWidgets Column
    const columns = useMemo(() => [
        {
        Header: 'Widget ID',
        accessor: 'id',
        filterable: false,
        },
        {
        Header: 'Widget Title',
        accessor: 'title',
        filterable: false,
        },
        {
            Header: 'Country',
            accessor: 'siteId',
            filterable: false,
            Cell: (cell) => {
                return getPageCountry(cell.value);
            },
        },
        {
            Header: 'City Page',
            Cell: (cellProps) => {
                return getPageCity(
                    cellProps.row.original.siteId,
                    cellProps.row.original.entityId
                );
            },
        },
        // {
        //     Header: 'provider Page',
        //     Cell: (cellProps) => {
        //         return getPageProvider(
        //             cellProps.row.original.siteId,
        //             cellProps.row.original.entityId
        //         );
        //     },
        // },
        {
            Header: 'Status',
            accessor: 'status',
            filterable: false,
            Cell: (cell) => {
                switch (cell.value) {
                case '1':
                case 1:
                    return (
                    <span className="badge text-uppercase badge-soft-info">
                        Active
                    </span>
                    );
                case '0':
                case 0:
                    return (
                    <span className="badge text-uppercase badge-soft-warning">
                        Block
                    </span>
                    );
                default:
                    return (
                    <span className="badge text-uppercase badge-soft-info">
                        {cell.value}
                    </span>
                    );
                }
            },
        },
        {
            Header: 'Action',
            Cell: (item) => {
                return (
                <ul className="list-inline hstack gap-2 mb-0">
                    <li className="list-inline-item">
                    <Link
                        to={'/manage-widget-cards/' + item.row.original.id}
                        className="text-muted d-inline-block"
                    >
                        <i className="ri-file-add-line fs-16"></i> Add / Manage Widget
                        Item
                    </Link>
                    </li>
                    <li className="list-inline-item edit">
                    <Link
                        to={'/manage-widgets/edit/' + item.row.original.id}
                        className="text-muted d-inline-block edit-item-btn"
                    >
                        <i className="ri-pencil-fill fs-16"></i> Edit Widget
                    </Link>
                    </li>
                </ul>
                );
            },
        },
    ]);

    // Export Modal
    const [isExportCSV, setIsExportCSV] = useState(false);

    useEffect(() => {
        if (manageWidgets && !isManageWidgetSuccess) {
          dispatch(onGetManageWidgets());
        }
    }, [dispatch, manageWidgets]);

    useEffect(() => {
        if (domains && !isDomainSuccess) {
          dispatch((onGetDomainsApi()));
        }
    }, [dispatch, domains]);

    useEffect(() => {
        if (cities && !isCitySuccess) {
          dispatch((onGetCities()));
        }
    }, [dispatch, cities]);

    useEffect(() => {
        setCity(cities);
    }, [cities]);
    
    
    // useEffect(() => {
    //     if (providers && !isProviderSuccess) {
    //       dispatch((onGetProviders()));
    //     }
    // }, [dispatch, providers]);

    // useEffect(() => {
    //     setProvider(providers);
    // }, [providers]);
    
    useEffect(() => {
        setDomain(domains);
    }, [domains]);

    useEffect(() => {
        setManageWidget(manageWidgets);
    }, [manageWidgets]);

    document.title = 'Manage Widgets | Admin & Dashboard';

    // console.log("manageWidgets", manageWidgets)
    return (
        <React.Fragment>
            <div className="page-content">
                <ExportCSVModal
                    show={isExportCSV}
                    onCloseClick={() => setIsExportCSV(false)}
                    data={manageWidgets}
                />
                <Container fluid>
                    <BreadCrumb title="Manage Widgets" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="manageWidgetList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                        <div>
                                            <h5 className="card-title mb-0">Widget List</h5>
                                        </div>
                                        </div>
                                        <div className="col-sm-auto ms-auto">
                                        <div>
                                            <Link
                                            to="/manage-widgets/add"
                                            className="btn btn-success"
                                            >
                                            <i className="ri-add-line align-bottom me-1"></i> Add
                                            Widget
                                            </Link>
                                        </div>
                                        </div>
                                    </Row>
                                </CardHeader>

                                <div className="card-body pt-0">
                                    <div>
                                        {isManageWidgetSuccess ? (
                                        <>
                                            {manageWidgets.length ? (
                                                <TableContainer
                                                    columns={columns}
                                                    data={manageWidgets || []}
                                                    isGlobalFilter={true}
                                                    isAddUserList={false}
                                                    customPageSize={10}
                                                    divClass="table-responsive mb-1"
                                                    tableClass="mb-0 align-middle table-borderless"
                                                    theadClass="table-light text-muted"
                                                    SearchPlaceholder="Search for Widgets..."
                                                />
                                            ) : (
                                                <div className="py-4 mt-4 text-center">
                                                    <lord-icon
                                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                                    trigger="loop"
                                                    colors="primary:#405189,secondary:#0ab39c"
                                                    style={{ width: '72px', height: '72px' }}
                                                    ></lord-icon>
                                                    <h5 className="mt-4">Sorry! No Result Found</h5>
                                                </div>
                                            )}
                                        </>
                                        ) : (
                                        <Loader error={error} />
                                        )}
                                    </div>

                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ManageWidgets;