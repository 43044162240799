import React, { useState } from 'react'
import { Button, Spinner } from 'reactstrap'

import { clearAllCache } from '../../helpers/backend_helper'

const GlobalClearCache = () => {

    const [loading, setLoading] = useState(false);

    const clearCache = async () => {
        try {
            setLoading(true);
            const resp = await clearAllCache();

            if(resp.statusCode == 200) {
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            console.log("got Error in clearing cache ====> ", error.message);
        }
    }

    if(loading) {
        return (
            <Button color='danger'>
                <Spinner color="light" size="sm">
                    Loading...
                </Spinner>
            </Button>
        )
    }

    return (
        <Button color='warning'
            onClick={clearCache}
        >
            Clear Cache
        </Button>
    )
}

export default GlobalClearCache