import { createSlice } from '@reduxjs/toolkit';
import { getKeys, updateKey } from './thunk';
export const initialState = {
  homepage: {},
  contact: {},
  aboutus: {},
  comparebroadband: {},
  highspeedplan: {},
  cheapestplan: {},
  topentertainmentplan: {},
  termscondition: {},
  privacypolicy: {},
  error: {},
};

const KeysSlice = createSlice({
  name: 'KeysSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getKeys.fulfilled, (state, action) => {
      let key = action.meta.arg ? action.meta.arg : action?.payload?.payload?.keyName,
        keyCreated = 'is' + key + 'Created',
        keySuccess = 'is' + key + 'Success';

      let data = action?.payload?.payload?.value;
      state[key] = !!data
        ? JSON.parse(data)
        : {
            tagline: '',
            headline: '',
            meta_title: '',
            meta_keyword: '',
            meta_description: '',
          };
      state[keyCreated] = false;
      state[keySuccess] = true;
    });

    builder.addCase(updateKey.fulfilled, (state, action) => {
      let key = action?.payload?.payload?.keyName;
      let data = action?.payload?.payload?.value;
      state[key] = !!data
        ? JSON.parse(data)
        : {
            tagline: '',
            headline: '',
            meta_title: '',
            meta_keyword: '',
            meta_description: '',
          };
    });
    builder.addCase(updateKey.rejected, (state, action) => {
      let errorKey = 'error' + action?.payload?.payload?.keyName;
      state[errorKey] = action.error.message || null;
    });
  },
});

export default KeysSlice.reducer;
