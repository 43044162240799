import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getReviews as getReviewsApi,
  updateReview as updateReviewApi,
} from '../../helpers/backend_helper';

export const getReviews = createAsyncThunk('getReviews', async () => {
  try {
    const response = getReviewsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateReview = createAsyncThunk('updateReview', async (review) => {
  try {
    const response = updateReviewApi(review);
    const data = await response;
    toast.success('Review Updateded Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('Review Updateded Failed', { autoClose: 3000 });
    return error;
  }
});
