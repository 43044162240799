import { APIClient } from './api_helper';

import * as url from './url_helper';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
export const postLogin = (data) => api.create(url.POST_LOGIN, data);
export const postProfile = (data) =>
  api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Domains
export const getDomains = () => api.get(url.GET_DOMAINS);
export const addNewDomain = (domain) => api.create(url.ADD_NEW_DOMAIN, domain);
export const updateDomain = (domain) => api.create(url.UPDATE_DOMAIN, domain);

// Countries
export const getCountries = () => api.get(url.GET_COUNTRIES);
export const addNewCountry = (country) =>
  api.create(url.ADD_NEW_COUNTRY, country);
export const updateCountry = (country) =>
  api.create(url.UPDATE_COUNTRY, country);

// Manage Leads
export const getLeads = () => api.get(url.GET_LEADS);

// Reported Abuses
export const getReportAbuses = () => api.get(url.GET_REPORTABUSES);

// Cities
export const getReviews = () => api.get(url.GET_REVIEWS);
export const updateReview = (review) => api.create(url.UPDATE_REVIEW, review);

// Cities
export const getCities = () => api.get(url.GET_CITIES);
export const addNewCity = (city) => api.create(url.ADD_NEW_CITY, city);
export const updateCity = (city) => api.create(url.UPDATE_CITY, city);
export const findCityByBroadbandId = (broadbandId) => api.get(url.FIND_CITIES_FROM_BROADBAND_ID + '/' + broadbandId);
export const updateCityByBroadbandId = (city) => api.put(url.UPDATE_CITIES_FROM_BROADBAND_ID, city);
export const deleteCityByBroadbandId = (city) => api.delete(url.DELETE_CITIES_FROM_BROADBAND_ID, city);

// Provider config
export const getProviders = () => api.get(url.GET_PROVIDERS);
export const addNewProvider = (provider) =>
  api.create(url.ADD_NEW_PROVIDER, provider);
export const updateProvider = (provider) =>
  api.create(url.UPDATE_PROVIDER, provider);

// BroadbandPlans config
export const getBroadbandPlans = () => api.get(url.GET_BROADBANDPLANS);
export const addNewBroadbandPlan = (broadbandPlan) =>
  api.create(url.ADD_NEW_BROADBANDPLAN, broadbandPlan);
export const updateBroadbandPlan = (broadbandPlan) =>
  api.create(url.UPDATE_BROADBANDPLAN, broadbandPlan);

// BroadbandAtrributes config
export const getBroadbandAttributes = () =>
  api.get(url.GET_BROADBAND_ATTRIBUTES);
export const addNewBroadbandAttribute = (broadbandAttribute) =>
  api.create(url.ADD_NEW_BROADBAND_ATTRIBUTE, broadbandAttribute);
export const updateBroadbandAttribute = (broadbandAttribute) =>
  api.create(url.UPDATE_BROADBAND_ATTRIBUTE, broadbandAttribute);

// Keys config
export const getKeys = (key) => api.get(url.GET_KEYS + '/' + key);
export const updateKey = (key) => api.create(url.UPDATE_KEY, key);


// Manage Widgets
export const getManageWidgets = () => api.get(url.GET_MANAGEWIDGETS);
export const addNewManageWidget = (manageWidget) =>
  api.create(url.ADD_NEW_MANAGEWIDGET, manageWidget);
export const updateManageWidget = (manageWidget) =>
  api.create(url.UPDATE_MANAGEWIDGET, manageWidget);

  // Providers Widgets
export const getProvidersWidgets = () => api.get(url.GET_PROVIDERSWIDGETS);
export const addNewProvidersWidget = (providersWidget) =>
  api.create(url.ADD_NEW_PROVIDERSWIDGET, providersWidget);
export const updateProvidersWidget = (providersWidget) =>
  api.create(url.UPDATE_PROVIDERSWIDGET, providersWidget);

  // Manage Widget Card
export const getManageWidgetCards = (key) =>
api.get(url.GET_MANAGEWIDGETCARDS + '/' + key);
export const addNewManageWidgetCard = (manageWidgetCard) =>
api.create(url.ADD_NEW_MANAGEWIDGETCARD, manageWidgetCard);
export const updateManageWidgetCard = (manageWidgetCard) =>
api.create(url.UPDATE_MANAGEWIDGETCARD, manageWidgetCard);

//Clear Cache
export const clearAllCache = () => api.get(url.CLEAR_CACHE);
