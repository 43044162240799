import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
  getDomains as onGetDomains,
  getBroadbandPlans as onGetBroadbandPlans,
  getProviders as onGetProviders,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';
import { isEmpty } from 'lodash';
import { Price, Rating } from '../../Components/Common/Utils';
import axios from 'axios';

const BroadbandPlans = () => {
  const dispatch = useDispatch();

  const {
    domains,
    isDomainSuccess,
    broadbandPlans,
    isBroadbandPlanCreated,
    isBroadbandPlanSuccess,
    error,
    providers,
    isProviderSuccess,
  } = useSelector((state) => ({
    domains: state.Domains.domains,
    isDomainSuccess: state.Domains.isDomainSuccess,
    broadbandPlans: state.BroadbandPlans.broadbandPlans,
    isBroadbandPlanCreated: state.BroadbandPlans.isBroadbandPlanCreated,
    isBroadbandPlanSuccess: state.BroadbandPlans.isBroadbandPlanSuccess,
    providers: state.Providers.providers,
    isProviderSuccess: state.Providers.isProviderSuccess,
    error: state.BroadbandPlans.error,
  }));

  const [broadbandPlan, setBroadbandPlan] = useState([]);
  const [filteredPlan, setFilteredPlan] = useState([]);
  const [domain, setDomain] = useState([]);
  const [provider, setProvider] = useState([]);

  const getDomainUrl = () => {
    return (
      'https://' +
      domains.find((item) => item.id == sessionStorage.getItem('siteid'))
        .domainName
    );
  };

  // const filterPlan = (event) => {
  //   let fPlan;
  //   if (event.target.value != '') {
  //     fPlan = broadbandPlan.filter(
  //       (item) => item.broadBand.providerId == event.target.value
  //     );
  //   } else {
  //     fPlan = broadbandPlan;
  //   }
  //   setFilteredPlan(fPlan);
  // };

  const changeProviderPlans = async (event) => {

    if(!event.target.value) {
      setFilteredPlan(broadbandPlan);
      return;
    }

    let response = await axios.get(`/admin/broadband/listPrivderId/${event.target.value}`)
    if(response.statusCode == 200) {
      let fPlan = response.payload;
      setFilteredPlan(fPlan);
    }
  
  }

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    setDomain(domains);
  }, [domains]);

  useEffect(() => {
    if (providers && !isProviderSuccess) {
      dispatch(onGetProviders());
    }
  }, [dispatch, providers]);

  useEffect(() => {
    setProvider(providers);
  }, [providers]);

  useEffect(() => {
    if (broadbandPlans && !isBroadbandPlanSuccess) {
      dispatch(onGetBroadbandPlans());
    }
  }, [dispatch, broadbandPlans]);

  useEffect(() => {
    setBroadbandPlan(broadbandPlans);
    setFilteredPlan(broadbandPlans);
  }, [broadbandPlans]);

  useEffect(() => {
    if (!isEmpty(broadbandPlans)) {
      setBroadbandPlan(broadbandPlans);
      setFilteredPlan(broadbandPlans);
    }
  }, [broadbandPlans]);

  useEffect(() => {
    if (isBroadbandPlanCreated) {
      setBroadbandPlan(null);
      setFilteredPlan(null);
      dispatch(onGetBroadbandPlans());
    }
  }, [dispatch, isBroadbandPlanCreated]);
  // BroadbandPlans Column
  const columns = useMemo(() => [
    {
      Header: 'Sorting Order',
      accessor: 'broadBand.sortingOrder',
      filterable: false,
    },
    {
      Header: 'Plan',
      accessor: 'broadBand.name',
      filterable: true,
      Cell: (plan) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <img
                  src={plan.row.original?.provider?.logo || ''}
                  className="img-fluid d-block"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <span className="text-dark">
                  {plan.row.original?.broadBand?.name}
                </span>
              </h5>
              <p className="text-muted mb-0">
                Cost:
                <span className="fw-medium">
                  <Price
                    cost={plan.row.original?.broadBand?.cost}
                    currency={plan.row.original?.broadBand?.currency}
                  />
                </span>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: '',
      accessor: 'broadBand.cost',
      hiddenColumns: true,
      Cell: (cell) => {
        return <input type="hidden" value={cell.value} />;
      },
    },
    {
      Header: 'Speed',
      accessor: 'broadBand.speed',
      filterable: true,
      Cell: (plan) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <span className="text-dark">
                  {plan.row.original?.broadBand?.speed}{' '}
                  {plan.row.original?.broadBand?.speedScale}
                </span>
              </h5>
              <p className="text-muted mb-0">
                Bandwidth:
                <span className="fw-medium">
                  {plan.row.original?.broadBand?.bandwidthSpeedScale ===
                    'unlimited' ||
                  plan.row.original?.broadBand?.bandwidth == '-1'
                    ? `Unlimited`
                    : `${plan.row.original?.broadBand?.bandwidth} ${plan.row.original?.broadBand?.bandwidthSpeedScale}/${plan.row.original?.broadBand?.bandwidthDurationScale}`}
                </span>
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: '',
      accessor: 'broadBand.speedScale',
      hiddenColumns: true,
      Cell: (cell) => {
        return <input type="hidden" value={cell.value} />;
      },
    },
    {
      Header: '',
      accessor: 'broadBand.bandwidthSpeedScale',
      hiddenColumns: true,
      Cell: (cell) => {
        return <input type="hidden" value={cell.value} />;
      },
    },
    {
      Header: '',
      accessor: 'broadBand.bandwidth',
      hiddenColumns: true,
      Cell: (cell) => {
        return <input type="hidden" value={cell.value} />;
      },
    },
    {
      Header: 'Rating',
      accessor: 'broadBand.rating',
      filterable: false,
      Cell: (cellProps) => {
        return <Rating {...cellProps} />;
      },
    },
    {
      Header: 'Sponsored',
      accessor: 'broadBand.sponsored',
      filterable: true,
    },
    {
      Header: 'Status',
      accessor: 'broadBand.status',
      filterable: true,
      Cell: (cell) => {
        switch (cell.value) {
          case '1':
          case 1:
            return (
              <span className="badge text-uppercase badge-soft-success">
                Active
              </span>
            );
          case '0':
          case 0:
            return (
              <span className="badge text-uppercase badge-soft-danger">
                Block
              </span>
            );
          default:
            return (
              <span className="badge text-uppercase badge-soft-info">
                {cell?.broadBand?.value}
              </span>
            );
        }
      },
    },
    {
      Header: 'Action',
      Cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item edit" title="Edit">
              <Link
                to={'/plans/edit/' + cellProps.row.original?.broadBand?.id}
                className="text-muted d-inline-block edit-item-btn"
              >
                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '}
                Edit
              </Link>
            </li>
            <li className="list-inline-item edit" title="View">
              <Link
                target="_blank"
                to={
                  getDomainUrl() +
                  '/broadband-plan/' +
                  //cellProps.row.original?.provider?.seoName +
                  //'/' +
                  cellProps.row.original?.broadBand?.seoName 
                  // '/' +
                  // cellProps.row.original?.broadBand?.id
                }
                className="text-muted d-inline-block edit-item-btn"
              >
                <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{' '}
                View
              </Link>
            </li>
          </ul>
        );
      },
    },
  ]);

  document.title = 'BroadbandPlans | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="BroadbandPlans" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="broadbandPlanList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          Broadband Plans List
                        </h5>
                      </div>
                    </div>

                    <div className="col-sm-auto">
                      <div>
                        {!!providers && providers.length ? (
                          <Link to="/plans/add" className="btn btn-success">
                            <i className="ri-add-line align-bottom me-1"></i>{' '}
                            Add BroadbandPlan
                          </Link>
                        ) : (
                          <Link to="/providers/add" className="btn btn-success">
                            <i className="ri-add-line align-bottom me-1"></i>{' '}
                            Add Provider First
                          </Link>
                        )}
                      </div>
                    </div>
                    {!!providers &&
                    providers.length 
                    //&& !!filteredPlan 
                    //&& filteredPlan.length 
                    ? (
                      <Col md={2}>
                        <select className="form-select" onChange={changeProviderPlans}>
                          <option value="">All Provider</option>
                          {providers.map((item, key) => (
                            <React.Fragment key={key}>
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            </React.Fragment>
                          ))}
                        </select>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isBroadbandPlanSuccess ? (
                      !!filteredPlan && filteredPlan.length ? (
                        <TableContainer
                          columns={columns}
                          data={filteredPlan || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={10}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-borderless"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search for broadbandPlan..."
                        />
                      ) : (
                        <div className="py-4 mt-4 text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: '72px', height: '72px' }}
                          ></lord-icon>
                          <h5 className="mt-4">Sorry! No Result Found</h5>
                        </div>
                      )
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BroadbandPlans;
