import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getKeys as getKeysApi,
  updateKey as updateKeyApi,
} from '../../helpers/backend_helper';

export const getKeys = createAsyncThunk('getKeys', async (key) => {
  try {
    const response = getKeysApi(key);
    return response;
  } catch (error) {
    return error;
  }
});

export const updateKey = createAsyncThunk('updateKey', async (key) => {
  try {
    const response = updateKeyApi(key);
    const data = await response;
    toast.success('Data Updated Successfully', { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error('Data Updated Failed', { autoClose: 3000 });
    return error;
  }
});
