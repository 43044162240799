//Include Both Helper File with needed methods
import { postLogin } from '../../../helpers/backend_helper';

import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from './reducer';

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;
    if (process.env.REACT_APP_API_URL) {
      response = postLogin({
        userid: user.userid,
        password: user.password,
      });
    }

    var data = await response;
    if (data.statusCode === 200) {
      sessionStorage.setItem('siteid', user.siteid);
      sessionStorage.setItem('authUser', JSON.stringify(data.payload));
      dispatch(loginSuccess(data));
      history('/dashboard');
    } else {
      dispatch(
        apiError({ status: 'errors', data: 'Incorrect username or password' })
      );
    }
  } catch (error) {
    dispatch({
      status: 'errors',
      data: 'Network Error! Try again after sometime',
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem('authUser');
    sessionStorage.removeItem('siteid');
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
