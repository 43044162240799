import React, { useState, useEffect, useMemo } from 'react';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardHeader,
} from 'reactstrap';
import ContactBoxFormComponents from '../../Components/Common/ContactBox';
import {
  getKeys as onGetKeys,
  updateKey as onUpdateKey,
} from '../../slices/thunks';

// Redux
import { useSelector, useDispatch } from 'react-redux';

//formik
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

const ManageContact = () => {
  const keyid = 'contact';
  document.title = 'Manage Contact | Admin & Dashboard';

  const dispatch = useDispatch();
  const { contact, iscontactCreated, iscontactSuccess, error } = useSelector(
    (state) => ({
      contact: state.Keys.contact,
      iscontactCreated: state.Keys.iscontactCreated,
      iscontactSuccess: state.Keys.iscontactSuccess,
      error: state.Keys.errorcontact,
    })
  );

  const [key, setKey] = useState([]);
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      label0: (key.formData && key.formData[0]?.label) || '',
      label1: (key.formData && key.formData[1]?.label) || '',
      label2: (key.formData && key.formData[2]?.label) || '',
      desc0: (key.formData && key.formData[0]?.desc) || '',
      desc1: (key.formData && key.formData[1]?.desc) || '',
      desc2: (key.formData && key.formData[2]?.desc) || '',
      value0: (key.formData && key.formData[0]?.value) || '',
      value1: (key.formData && key.formData[1]?.value) || '',
      value2: (key.formData && key.formData[2]?.value) || '',
      meta_title: (key && key.meta_title) || '',
      meta_keyword: (key && key.meta_keyword) || '',
      meta_description: (key && key.meta_description) || '',
    },
    validationSchema: Yup.object({
      label0: Yup.string().required('Please enter label text 1'),
      label1: Yup.string().required('Please enter label text 2'),
      label2: Yup.string().required('Please enter label text 3'),
      desc0: Yup.string().required('Please enter Desc text 1'),
      desc1: Yup.string().required('Please enter Desc text 2'),
      desc2: Yup.string().required('Please enter Desc text 3'),
      value0: Yup.string().required('Please enter Value text 1'),
      value1: Yup.string().required('Please enter Value text 2'),
      value2: Yup.string().required('Please enter Value text 3'),
      meta_title: Yup.string()
        .max(
          100,
          'Max 100 characters allowed, though its best to have maximum 70 charaters in title'
        )
        .required('Please enter a meta title'),
      meta_keyword: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, keywords must not have more than 30 words'
        )
        .required('Please enter a meta keyword'),
      meta_description: Yup.string()
        .max(
          500,
          'Max 500 characters allowed, though it best to have not more than 155 characters in description'
        )
        .required('Please enter a meta description'),
    }),
    onSubmit: async (values) => {
      const valueKeys = Object.keys(values).filter((key) =>
        key.startsWith('label')
      );
      const formData = valueKeys.map((valueKey) => ({
        label: values[valueKey],
        desc: values[valueKey.replace('label', 'desc')],
        value: values[valueKey.replace('label', 'value')],
      }));

      const value = {
        meta_title: values.meta_title,
        meta_keyword: values.meta_keyword,
        meta_description: values.meta_description,
        formData: [...formData],
      };
      // Process the form data as needed
      const updateKey = {
        projectId: 3,
        keyName: keyid,
        value: JSON.stringify(value),
      };
      // update Key
      dispatch(onUpdateKey(updateKey));
      //validation.resetForm();
    },
  });

  useEffect(() => {
    if (contact && !iscontactSuccess) {
      dispatch(onGetKeys(keyid));
    }
  }, [dispatch, contact]);

  useEffect(() => {
    setKey({
      meta_title: contact?.meta_title || '',
      meta_keyword: contact?.meta_keyword || '',
      meta_description: contact?.meta_description || '',
      formData: [
        {
          label: (!!contact?.formData && contact?.formData[0]?.label) || '',
          desc: (!!contact?.formData && contact?.formData[0]?.desc) || '',
          value: (!!contact?.formData && contact?.formData[0].value) || '',
        },
        {
          label: (!!contact?.formData && contact?.formData[1]?.label) || '',
          desc: (!!contact?.formData && contact?.formData[1]?.desc) || '',
          value: (!!contact?.formData && contact?.formData[1]?.value) || '',
        },
        {
          label: (!!contact?.formData && contact?.formData[2]?.label) || '',
          desc: (!!contact?.formData && contact?.formData[2]?.desc) || '',
          value: (!!contact?.formData && contact?.formData[2]?.value) || '',
        },
      ],
    });
  }, [contact]);

  useEffect(() => {
    if (iscontactCreated) {
      setKey([0, 1, 2]);
      dispatch(onGetKeys(keyid));
    }
  }, [dispatch, iscontactCreated]);

  const siteId = sessionStorage.getItem('siteid');

  if(siteId && siteId != 8) {
    return (
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Manage Contact" pageTitle="Dashboard" />
          <p>
            This page should be managed from broadband.asia domain.
          </p>
        </Container>
      </div>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Manage Contact" pageTitle="Dashboard" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            {key.formData &&
              key.formData.map((item, index) => {
                return (
                  <Col lg={4} key={`${index}`}>
                    <ContactBoxFormComponents
                      validation={validation}
                      valueKey={`${index}`}
                    />
                  </Col>
                );
              })}
          </Row>
          <Card>
            <CardBody>
              <Row>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="meta-title-input">
                    Meta title
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter meta title"
                    id="meta-title-input"
                    name="meta_title"
                    value={validation.values.meta_title || ''}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.meta_title &&
                      validation.touched.meta_title
                        ? true
                        : false
                    }
                  />
                  {validation.errors.meta_title &&
                  validation.touched.meta_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.meta_title}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="meta-keywords-input">
                    Meta Keywords
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter meta keywords"
                    id="meta-keywords-input"
                    name="meta_keyword"
                    value={validation.values.meta_keyword || ''}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.meta_keyword &&
                      validation.touched.meta_keyword
                        ? true
                        : false
                    }
                  />
                  {validation.errors.meta_keyword &&
                  validation.touched.meta_keyword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.meta_keyword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div>
                  <Label
                    className="form-label"
                    htmlFor="meta-description-input"
                  >
                    Meta Description
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="meta-description-input"
                    placeholder="Enter meta description"
                    name="meta_description"
                    rows="3"
                    value={validation.values.meta_description || ''}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.meta_description &&
                      validation.touched.meta_description
                        ? true
                        : false
                    }
                  ></Input>
                  {validation.errors.meta_description &&
                  validation.touched.meta_description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.meta_description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Row>
            </CardBody>
          </Card>
          <div className="text-end mb-3">
            <button type="submit" className="btn btn-success w-sm">
              Submit
            </button>
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </Form>
      </Container>
    </div>
  );
};

export default ManageContact;
