import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
  getCountries as onGetCountries,
  addNewCountry as onAddNewCountry,
  updateCountry as onUpdateCountry,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';

const Countries = () => {
  const dispatch = useDispatch();

  const { countries, isCountryCreated, isCountrySuccess, error } = useSelector(
    (state) => ({
      countries: state.Countries.countries,
      isCountryCreated: state.Countries.isCountryCreated,
      isCountrySuccess: state.Countries.isCountrySuccess,
      error: state.Countries.error,
    })
  );

  const [isEdit, setIsEdit] = useState(false);
  const [country, setCountry] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCountry(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (country && country.name) || '',
      shortName: (country && country.shortName) || '',
      isdCode: (country && country.isdCode) || '',
      currency: (country && country.currency) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Country Name'),
      shortName: Yup.string().required('Please Enter Country Code'),
      isdCode: Yup.string().required('Please Enter ISD Code of the Country'),
      currency: Yup.string().required('Please Enter Countries Currency'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCountry = {
          id: country ? country.id : 0,
          name: values.name,
          shortName: values.shortName,
          isdCode: values.isdCode,
          currency: values.currency,
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // update country
        dispatch(onUpdateCountry(updateCountry));
        validation.resetForm();
      } else {
        const newCountry = {
          name: values['name'],
          shortName: values['shortName'],
          isdCode: values['isdCode'],
          currency: values['currency'],
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // save new country
        dispatch(onAddNewCountry(newCountry));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  // const handleCountryClick = useCallback(
  //   (arg) => {
  //     const country = arg;

  //     setCountry({
  //       id: country.id,
  //       name: country.name,
  //       shortName: country.shortName,
  //       isdCode: country.isdCode,
  //       currency: country.currency,
  //     });

  //     setIsEdit(true);
  //     toggle();
  //   },
  //   [toggle]
  // );

  useEffect(() => {
    if (countries && !isCountrySuccess) {
      dispatch(onGetCountries());
    }
  }, [dispatch, countries]);

  useEffect(() => {
    setCountry(countries);
  }, [countries]);

  useEffect(() => {
    if (!isEmpty(countries)) {
      setCountry(countries);
      setIsEdit(false);
    }
  }, [countries]);

  // Add Data
  const handleCountryClicks = () => {
    setCountry('');
    setIsEdit(false);
    toggle();
  };

  useEffect(() => {
    if (isCountryCreated) {
      setCountry(null);
      dispatch(onGetCountries());
    }
  }, [dispatch, isCountryCreated]);

  // Countries Column
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        hiddenColumns: true,
        Cell: (cell) => {
          return <input type="hidden" value={cell.value} />;
        },
      },
      {
        Header: 'Country',
        accessor: 'name',
        filterable: false,
      },
      {
        Header: 'Country Code',
        accessor: 'shortName',
        filterable: false,
      },
      {
        Header: 'ISD Code',
        accessor: 'isdCode',
        filterable: false,
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        filterable: false,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={'/countries/edit/' + cellProps.row.original?.id}
                  className="text-primary d-inline-block edit-item-btn"
                  // onClick={() => {
                  //   const countryData = cellProps.row.original;
                  //   handleCountryClick(countryData);
                  // }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{' '} Edit
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Countries | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={countries}
        />

        <Container fluid>
          <BreadCrumb title="Countries" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="countryList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Country List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn hide-tmpo"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Country
                        </button>
                        <button
                          type="button"
                          className="btn btn-info ms-1"
                          onClick={() => setIsExportCSV(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isCountrySuccess ? (
                      <>
                        {countries.length ? (
                          <TableContainer
                            columns={columns}
                            data={countries || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            className="custom-header-css"
                            handleCountryClick={handleCountryClicks}
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for country..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? 'Edit Country' : 'Add Country'}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: 'none' }}
                        >
                          <Label htmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="countryname-field"
                            className="form-label"
                          >
                            Country Name
                          </Label>
                          <Input
                            name="name"
                            id="countryname-field"
                            className="form-control"
                            placeholder="Enter Country Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ''}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="shortName-field"
                            className="form-label"
                          >
                            Country Code
                          </Label>
                          <Input
                            name="shortName"
                            type="text"
                            id="shortName-field"
                            placeholder="Enter Country Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shortName || ''}
                            invalid={
                              validation.touched.shortName &&
                              validation.errors.shortName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.shortName &&
                          validation.errors.shortName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shortName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="isdCode-field" className="form-label">
                            ISD Code
                          </Label>
                          <Input
                            name="isdCode"
                            type="text"
                            id="isdCode-field"
                            placeholder="Enter ISD Code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.isdCode || ''}
                            invalid={
                              validation.touched.isdCode &&
                              validation.errors.isdCode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.isdCode &&
                          validation.errors.isdCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.isdCode}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="currency-field"
                            className="form-label"
                          >
                            Currency
                          </Label>
                          <Input
                            name="currency"
                            type="text"
                            id="currency-field"
                            placeholder="Enter Currency"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currency || ''}
                            invalid={
                              validation.touched.currency &&
                              validation.errors.currency
                                ? true
                                : false
                            }
                          />
                          {validation.touched.currency &&
                          validation.errors.currency ? (
                            <FormFeedback type="invalid">
                              {validation.errors.currency}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {!!isEdit ? 'Update' : 'Add Country'}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Countries;
