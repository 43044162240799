import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
    getManageWidgets as getManageWidgetsApi,
    addNewManageWidget as addNewManageWidgetApi,
    updateManageWidget as updateManageWidgetApi,
} from '../../helpers/backend_helper';
  
export const getManageWidgets = createAsyncThunk(
    'getManageWidgets',
    async () => {
      try {
        const response = getManageWidgetsApi();
        return response;
      } catch (error) {
        return error;
      }
    }
);
  
export const updateManageWidget = createAsyncThunk(
    'updateManageWidget',
    async (manageWidget) => {
      try {
        const response = updateManageWidgetApi(manageWidget);
        const data = await response;
        toast.success('Widget Updated Successfully', { autoClose: 3000 });
        return data;
      } catch (error) {
        toast.error('Widget Updation Failed', { autoClose: 3000 });
        return error;
      }
    }
);
  
export const addNewManageWidget = createAsyncThunk(
    'addNewManageWidget',
    async (manageWidget) => {
      try {
        const response = addNewManageWidgetApi(manageWidget);
        const data = await response;
        toast.success('Widget Added Successfully', { autoClose: 3000 });
        return data;
      } catch (error) {
        toast.error('Widget Addition Failed', { autoClose: 3000 });
        return error;
      }
    }
);