import { createSlice } from '@reduxjs/toolkit';
import { getReviews, addNewReview, updateReview } from './thunk';
export const initialState = {
  reviews: [],
  error: {},
};

const ReviewsSlice = createSlice({
  name: 'ReviewsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getReviews.fulfilled, (state, action) => {
      state.reviews = action.payload.payload;
      state.isReviewSuccess = true;
    });

    builder.addCase(getReviews.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isReviewSuccess = false;
    });

    builder.addCase(updateReview.fulfilled, (state, action) => {
      state.reviews = state.reviews.map((review) =>
        review.id.toString() === action.payload.payload.id.toString()
          ? { ...review, ...action.payload.payload }
          : review
      );
    });
    builder.addCase(updateReview.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default ReviewsSlice.reducer;
