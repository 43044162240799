import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getManageWidgetCards as getManageWidgetCardsApi,
  addNewManageWidgetCard as addNewManageWidgetCardApi,
  updateManageWidgetCard as updateManageWidgetCardApi,
} from '../../helpers/backend_helper';

export const getManageWidgetCards = createAsyncThunk(
  'getManageWidgetCards',
  async (key) => {
    try {
      const response = getManageWidgetCardsApi(key);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateManageWidgetCard = createAsyncThunk(
  'updateManageWidgetCard',
  async (manageWidgetCard) => {
    try {
      const response = updateManageWidgetCardApi(manageWidgetCard);
      const data = await response;
      toast.success('ManageWidgetCard Updated Successfully', {
        autoClose: 3000,
      });
      return data;
    } catch (error) {
      toast.error('ManageWidgetCard Updation Failed', { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewManageWidgetCard = createAsyncThunk(
  'addNewManageWidgetCard',
  async (manageWidgetCard) => {
    try {
      const response = addNewManageWidgetCardApi(manageWidgetCard);
      const data = await response;
      toast.success('ManageWidgetCard Added Successfully', { autoClose: 3000 });
      return data;
    } catch (error) {
      toast.error('ManageWidgetCard Addition Failed', { autoClose: 3000 });
      return error;
    }
  }
);