import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

//redux
import { useSelector, useDispatch } from 'react-redux';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { editProfile, resetProfileFlag } from '../../slices/thunks';

const UserProfile = () => {
  const dispatch = useDispatch();

  const [email, setemail] = useState('admin@gmail.com');
  const [idx, setidx] = useState('1');

  const [userName, setUserName] = useState('Admin');

  const { user, success, error } = useSelector((state) => ({
    user: state.Profile.user,
    success: state.Profile.success,
    error: state.Profile.error,
  }));

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'));

      if (!isEmpty(user)) {
        obj.username = user.username;
        sessionStorage.removeItem('authUser');
        sessionStorage.setItem('authUser', JSON.stringify(obj));
      }

      setUserName(obj.username);
      setemail(obj.email);
      setidx(obj.id || '1');

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: userName || 'Admin',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    },
  });

  document.title = 'Profile';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Username Updated To {userName}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userName || 'Admin'}</h5>
                        <p className="mb-1">Email Id : {email}</p>
                        <p className="mb-0">Id No : #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
