import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getCountries as getCountriesApi,
  addNewCountry as addNewCountryApi,
  updateCountry as updateCountryApi,
} from '../../helpers/backend_helper';

export const getCountries = createAsyncThunk('getCountries', async () => {
  try {
    const response = getCountriesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const updateCountry = createAsyncThunk(
  'updateCountry',
  async (country) => {
    try {
      const response = updateCountryApi(country);
      const data = await response;
      toast.success('Country Updateded Successfully', { autoClose: 3000 });
      return data;
    } catch (error) {
      toast.error('Country Updateded Failed', { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewCountry = createAsyncThunk(
  'addNewCountry',
  async (country) => {
    try {
      const response = addNewCountryApi(country);
      const data = await response;
      toast.success('Country Added Successfully', { autoClose: 3000 });
      return data;
    } catch (error) {
      toast.error('Country Added Failed', { autoClose: 3000 });
      return error;
    }
  }
);
