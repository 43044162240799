import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Input,
    Label,
    FormFeedback,
    Form,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

//redux
import {
    updateCountry as onUpdateCountry,
    addNewCountry as onAddNewCountry,
    getCountries as onGetCountries,
} from '../../slices/thunks';

import BreadCrumb from '../../Components/Common/BreadCrumb';

const AddCountry = () => {

    const { countryid } = useParams();
    document.title = 'Country | Admin & Dashboard';

    let content = "";
    const history = useNavigate();
    const dispatch = useDispatch();
  

    const {
        countries,
        isCountrySuccess,
    } = useSelector((state) => ({
        countries: state.Countries.countries,
        isCountrySuccess: state.Countries.isCountrySuccess,
    }));

    const [isEdit, setIsEdit] = useState(false); 
    const [currentCountry, setCurrentCountry] = useState([]); 

    const validation = useFormik({
        enableReinitialize: true,
    
        initialValues: {
            name: (currentCountry && currentCountry.name) || '',
            field1:
            (currentCountry && currentCountry.field1) || '',
            field2:
            (currentCountry && currentCountry.field2) || '',
        },
        validationSchema: Yup.object({
          name: Yup.string().required('Please enter a plan name'),
        }),
        onSubmit: (values) => {
          if (isEdit) {
            const updateCountry = {
                id: currentCountry ? currentCountry.id : countryid,
                name: values.name,
                shortName: values['shortName'],
                isdCode: values['isdCode'],
                currency: values['currency'],
                field1: values.field1,
                projectId: 3,
                siteId: sessionStorage.getItem('siteid'),
            }
            dispatch(onUpdateCountry(updateCountry));
            history('/countries');
            validation.resetForm();
          } else {
                const newCountry = {
                    name: values['name'],
                    shortName: values['shortName'],
                    isdCode: values['isdCode'],
                    currency: values['currency'],
                    projectId: 3,
                    siteId: sessionStorage.getItem('siteid'),
                };
                // save new country
                dispatch(onAddNewCountry(newCountry));
                validation.resetForm();
          }
        },
    });

    useEffect(() => {
        if (countries && !isCountrySuccess) {
          dispatch(onGetCountries());
        }
    }, [dispatch, countries]);

    useEffect(() => {
        if (!isEmpty(currentCountry)) {
          setIsEdit(false);
        }
    }, [countries]);

    useEffect(() => {
        if (typeof countryid != 'undefined' && !!countryid) {
          const fetchedElem = countries.find(
            (elem) => elem.id == countryid
          );
          if (typeof fetchedElem != 'undefined') {
            setCurrentCountry({ ...fetchedElem });
            setIsEdit(true);
          } else {
            history('/countries');
          }
        }
    }, [countryid]);

    const editorHandler = (event, editor) => {
        // Get the editor content
        var content = editor.getData();
      
        // Remove empty paragraphs
        content = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
      
        // Set the field value with the updated content
        validation.setFieldValue('field1', content);
    };

    const editorHandler2 = (event, editor) => {
        // Get the editor content
        var content = editor.getData();
      
        // Remove empty paragraphs
        content = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
      
        // Set the field value with the updated content
        validation.setFieldValue('field2', content);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb
                    title={!!isEdit ? 'Update Country' : 'Add Country'}
                    pageTitle="Dashboard"
                />
                {/* Form Starts */}
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        {/* Left Section */}
                        <Col lg={8}>
                            <Card>
                                <CardHeader>
                                <h5 className="card-title mb-0">
                                    {!!isEdit ? 'Country Edit details' : 'Add Country'}
                                </h5>
                                </CardHeader>
                                <CardBody>
                                    {/* Row 1 */}
                                    <Row>
                                        <Col>
                                            <div className="mb-3">
                                                <Label
                                                    className="form-label"
                                                    htmlFor="plan-title-input"
                                                >
                                                    Country Name*
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="plan-title-input"
                                                    placeholder="Enter plan title"
                                                    name="name"
                                                    value={validation.values.name || ''}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={
                                                        validation.errors.name && validation.touched.name
                                                        ? true
                                                        : false
                                                    }
                                                />
                                                {validation.errors.name && validation.touched.name ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.name}
                                                </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* Row 2 */}
                                    <Row style={{ marginBottom: 30 }}>
                                        <Col>
                                            <Label>Country Top Data</Label>
                                            <CKEditor
                                                editor={Editor}
                                                data={validation.values.field1 || ''}
                                                onChange={editorHandler}
                                            />
                                        </Col>
                                    </Row>
                                    {/* Row 3 */}
                                    {/* <Row>
                                        <Col>
                                        <Label>Country Mid Section Data</Label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={validation.values.field2 || ''}
                                            onChange={editorHandler2}
                                            config={ {
                                                plugins: [ Essentials ],
                                                toolbar: [ ]
                                            } }
                                        />
                                        </Col>
                                    </Row> */}
                                </CardBody>
                            </Card>
                        
                            <div className="text-end mb-3">
                                <button type="submit" className="btn btn-success w-sm">
                                {!!isEdit ? 'Update' : 'Submit'}
                                </button>
                            </div>
                        </Col>

                        {/* Right Section */}
                        <Col lg={4}>

                        </Col>
                    </Row>
                    </Form>
            </Container>
        </div>
    )
}

export default AddCountry