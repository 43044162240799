import { createSlice } from '@reduxjs/toolkit';
import {
  getProvidersWidgets,
  addNewProvidersWidget,
  updateProvidersWidget,
} from './thunk';
export const initialState = {
  providersWidgets: [],
  error: {},
};

const ProvidersWidgetsSlice = createSlice({
  name: 'ProvidersWidgetsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getProvidersWidgets.fulfilled, (state, action) => {
      state.providersWidgets = action.payload.payload;
      state.isProvidersWidgetCreated = false;
      state.isProvidersWidgetSuccess = true;
    });

    builder.addCase(getProvidersWidgets.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isProvidersWidgetCreated = false;
      state.isProvidersWidgetSuccess = false;
    });

    builder.addCase(addNewProvidersWidget.fulfilled, (state, action) => {
      state.providersWidgets.push(action.payload.payload);
      state.isProvidersWidgetCreated = true;
    });
    builder.addCase(addNewProvidersWidget.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateProvidersWidget.fulfilled, (state, action) => {
      state.providersWidgets = state.providersWidgets.map((providersWidget) =>
      providersWidget.id.toString() === action.payload.payload.id.toString()
          ? { ...providersWidget, ...action.payload.payload }
          : providersWidget
      );
    });
    builder.addCase(updateProvidersWidget.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default ProvidersWidgetsSlice.reducer;
