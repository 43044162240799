import { createSlice } from '@reduxjs/toolkit';
import {
  getBroadbandPlans,
  addNewBroadbandPlan,
  updateBroadbandPlan,
} from './thunk';
export const initialState = {
  broadbandPlans: [],
  error: {},
};

const BroadbandPlansSlice = createSlice({
  name: 'BroadbandPlansSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getBroadbandPlans.fulfilled, (state, action) => {
      state.broadbandPlans = action.payload.payload;
      state.isBroadbandPlanCreated = false;
      state.isBroadbandPlanSuccess = true;
    });

    builder.addCase(getBroadbandPlans.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isBroadbandPlanCreated = false;
      state.isBroadbandPlanSuccess = false;
    });

    builder.addCase(addNewBroadbandPlan.fulfilled, (state, action) => {
      state.broadbandPlans.push(action.payload.payload);
      state.isBroadbandPlanCreated = true;
    });
    builder.addCase(addNewBroadbandPlan.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateBroadbandPlan.fulfilled, (state, action) => {
      state.broadbandPlans = state.broadbandPlans.map((broadbandPlan) =>
        broadbandPlan.broadBand.id.toString() ===
        action.payload.payload.id.toString()
          ? { ...broadbandPlan, ...action.payload.payload }
          : broadbandPlan
      );
      state.isBroadbandPlanSuccess = false;
    });
    builder.addCase(updateBroadbandPlan.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default BroadbandPlansSlice.reducer;
