import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getBroadbandAttributes as getBroadbandAttributesApi,
  addNewBroadbandAttribute as addNewBroadbandAttributeApi,
  updateBroadbandAttribute as updateBroadbandAttributeApi,
} from '../../helpers/backend_helper';

export const getBroadbandAttributes = createAsyncThunk(
  'getBroadbandAttributes',
  async () => {
    try {
      const response = getBroadbandAttributesApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateBroadbandAttribute = createAsyncThunk(
  'updateBroadbandAttribute',
  async (broadbandAttribute) => {
    try {
      const response = updateBroadbandAttributeApi(broadbandAttribute);
      const data = await response;
      toast.success('BroadbandAttribute Updateded Successfully', {
        autoClose: 3000,
      });
      return data;
    } catch (error) {
      toast.error('BroadbandAttribute Updateded Failed', { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewBroadbandAttribute = createAsyncThunk(
  'addNewBroadbandAttribute',
  async (broadbandAttribute) => {
    try {
      const response = addNewBroadbandAttributeApi(broadbandAttribute);
      const data = await response;
      toast.success('BroadbandAttribute Added Successfully', {
        autoClose: 3000,
      });
      return data;
    } catch (error) {
      toast.error('BroadbandAttribute Added Failed', { autoClose: 3000 });
      return error;
    }
  }
);
