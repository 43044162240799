import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  addNewProvidersWidget as onAddNewProvidersWidget,
  updateProvidersWidget as onUpdateProvidersWidget,
  getDomains as onGetDomains,
} from "../../slices/thunks";

import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// status
import StatusMenu from "../../Data/status.json";
import axios from "axios";

const AddProvidersWidget = () => {
  const { providersWidgetid } = useParams();
  document.title = "Providers Widgets | Admin & Dashboard";

  const history = useNavigate();
  const dispatch = useDispatch();

  const {
    providersWidgets,
    domains,
    isDomainSuccess,
  } = useSelector((state) => ({
    providersWidgets: state.ProvidersWidgets.providersWidgets,
    domains: state.Domains.domains,
    isDomainSuccess: state.Domains.isDomainSuccess,
  }));

  const [providersWidget, setProvidersWidget] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [city, setCity] = useState([]);
  const [subPage, setSubPage] = useState([]);
  const [providersDomain, setProvidersDomain] = useState([]);
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (providersWidget && providersWidget.name) || "",
      title: (providersWidget && providersWidget.title) || "",
      description: (providersWidget && providersWidget.description) || "",
      freetext: (providersWidget && providersWidget.freetext) || "",
      status: (providersWidget && providersWidget.status) || "",
      countryId: (providersWidget && providersWidget.countryId) || "",
      cityId: (providersWidget && providersWidget.cityId) || "",
      sortingOrder: (providersWidget && providersWidget.sortingOrder) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a widget short name"),
      title: Yup.string().required("Please enter a widget title heading"),
      status: Yup.string().required("Please select widget status"),
      countryId: Yup.string().required("Please select country"),
      cityId: Yup.string().required("Please select providers"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateProvidersWidget = {
          id: providersWidget ? providersWidget.id : providersWidgetid,
          name: values.name,
          title: values.title,
          description: values.description,
          freetext: values.freetext,
          status: values.status,
          //countryId: parseInt(values.countryId),
          cityId: values.cityId === "all" ? null : parseInt(values.cityId),
          entityId: parseInt(values.cityId),
          sortingOrder: values.sortingOrder,
          projectId: 3,
          siteId:
            parseInt(values.countryId) || sessionStorage.getItem("siteid"),
        };
        // update ProvidersWidget
        dispatch(onUpdateProvidersWidget(updateProvidersWidget));
        history("/providers-widgets");
        validation.resetForm();
      } else {
        const newProvidersWidget = {
          name: values["name"],
          title: values["title"],
          description: values["description"],
          freetext: values["freetext"],
          status: values["status"],
          //countryId: parseInt(values["countryId"]),
          entityId: values.cityId === "all" ? null : parseInt(values.cityId),
          entityId: parseInt(values["cityId"]),
          sortingOrder: values["sortingOrder"],
          projectId: 3,
          siteId: parseInt(values["countryId"]),
        };

        // save new ProvidersWidget
        dispatch(onAddNewProvidersWidget(newProvidersWidget));
        history("/providers-widgets");
        validation.resetForm();
      }
    },
  });

  const selectPageOptions = async (countryId) => {
    if (countryId == "") {
      setSubPage([]);
    } else {
      try {
        let response = await axios.get(
          `https://www.parislondrestrain.fr/admin/provider/list`,
          {
            headers: {
              siteid: `${countryId}`,
              projectid: "3",
            },
          }
        );

        if (response.payload) {
          setCity(response.payload);
        }
      } catch (error) {}

      setSubPage([{ id: 0, name: "Main Page" }]);
    }
  };

  const selectPage = (event) => {
    let cityId = event.target.value;
    if (cityId) {
      validation.setFieldValue("countryId", cityId);
      validation.setFieldValue("cityId", "");
      selectPageOptions(cityId);
    }
  };

  useEffect(() => {
    if (typeof providersWidgetid != "undefined" && !!providersWidgetid) {
      const fetchedElem = providersWidgets.find(
        (elem) => elem.id == providersWidgetid
      );
      if (typeof fetchedElem != "undefined") {
        setProvidersWidget({
          id: fetchedElem.id,
          name: fetchedElem.name || "",
          title: fetchedElem.title || "",
          description: fetchedElem.description || "",
          freetext: fetchedElem.freetext || "",
          status: fetchedElem.status?.toString() || "",
          sortingOrder: fetchedElem.sortingOrder || "",
          countryId: fetchedElem.countryId?.toString() || "",
          cityId: fetchedElem.cityId?.toString() || "",
        });
        selectPageOptions(fetchedElem.countryId?.toString() || "");
        setIsEdit(true);
      } else {
        history("/providers-widgets");
      }
    }
  }, [providersWidgetid]);

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    if (sessionStorage.getItem("siteid")) {
      const filteredDomain = domains.filter(
        (item) => item.id == sessionStorage.getItem("siteid")
      );
      setProvidersDomain(filteredDomain);
    }
  }, [domains]);

  const editorHandler = (event, editor) => {
    validation.setFieldValue("description", editor.getData());
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={!!isEdit ? "Update Widget" : "Add Widget"}
          pageTitle="Dashboard"
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="providersWidget-title-input"
                        >
                          Widget ShortName for identification purpose
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="providersWidget-title-input"
                          placeholder="Enter Widget name"
                          name="name"
                          onChange={validation.handleChange}
                          value={validation.values.name || ""}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.errors.name && validation.touched.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && validation.touched.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="providersWidget-title-input"
                        >
                          Widget Title / Heading
                        </Label>
                        <div className="input-group">
                          <Input
                            type="text"
                            className="form-control"
                            id="providersWidget-title-input"
                            aria-describedby="providersWidget-addon"
                            placeholder="Enter widget heading title here"
                            name="title"
                            value={validation.values.title || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.title &&
                              validation.touched.title
                                ? true
                                : false
                            }
                          />
                          {validation.errors.title &&
                          validation.touched.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="description-input"
                        >
                          Description
                        </Label>
                        <CKEditor
                          editor={Editor}
                          data={validation.values.description || ""}
                          onChange={editorHandler}
                        />
                        {/* <Input
                          type="textarea"
                          className="form-control"
                          id="description-input"
                          placeholder="Enter description"
                          name="description"
                          rows="5"
                          value={validation.values.description || ""}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.description &&
                            validation.touched.description
                              ? true
                              : false
                          }
                        ></Input>
                        {validation.errors.description &&
                        validation.touched.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="freetext-input">
                          Sub Description (optional)
                        </Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          id="freetext-input"
                          placeholder="Enter sub description"
                          name="freetext"
                          rows="5"
                          value={validation.values.freetext || ""}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.freetext &&
                            validation.touched.freetext
                              ? true
                              : false
                          }
                        ></Input>
                        {validation.errors.freetext &&
                        validation.touched.freetext ? (
                          <FormFeedback type="invalid">
                            {validation.errors.freetext}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button>
              </div>
            </Col>
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Miscellenaeous</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="sortingOrder-field" className="form-label">
                      Sorting Order
                    </Label>

                    <Input
                      name="sortingOrder"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="sortingOrder-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sortingOrder || ""}
                      invalid={
                        validation.errors.sortingOrder &&
                        validation.touched.sortingOrder
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sortingOrder &&
                    validation.errors.sortingOrder ? (
                      <FormFeedback type="invalid">
                        {validation.errors.sortingOrder}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="status-field" className="form-label">
                      Status
                    </Label>

                    <Input
                      name="status"
                      type="select"
                      className="form-select"
                      id="status-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status || ""}
                      invalid={
                        validation.errors.status && validation.touched.status
                          ? true
                          : false
                      }
                    >
                      {StatusMenu.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Input>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>

                  {/* providers Menu */}
                  {isDomainSuccess && providersDomain.length ? (
                    <div className="mb-3">
                      <Label htmlFor="menu-field" className="form-label">
                        Select Domain
                      </Label>

                      <Input
                        name="countryId"
                        type="select"
                        className="form-select"
                        id="countryId-field"
                        onChange={selectPage}
                        onBlur={validation.handleBlur}
                        value={validation.values.countryId || ""}
                        invalid={
                          validation.errors.countryId &&
                          validation.touched.countryId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {providersDomain.map((item, key) => {
                          return (
                            <option value={item.id} key={key}>
                              {item.websiteName || item.domainName}
                            </option>
                          );
                        })}
                      </Input>
                      {validation.touched.countryId &&
                      validation.errors.countryId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.countryId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                  {subPage.length || city.length ? (
                    <div className="mb-3">
                      <Label htmlFor="cityId-field" className="form-label">
                        Select Providers
                      </Label>
                      <Input
                        name="cityId"
                        type="select"
                        className="form-select"
                        id="cityId-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cityId || ""}
                        invalid={
                          validation.errors.cityId && validation.touched.cityId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {city.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.cityId && validation.errors.cityId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.cityId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default AddProvidersWidget;
