import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col, Card, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { getBroadbandAttributes as onGetBroadbandAttributes } from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';

const BroadbandAttributes = () => {
  const dispatch = useDispatch();

  const {
    broadbandAttributes,
    isBroadbandAttributeCreated,
    isBroadbandAttributeSuccess,
    error,
  } = useSelector((state) => ({
    broadbandAttributes: state.BroadbandAttributes.broadbandAttributes,
    isBroadbandAttributeCreated:
      state.BroadbandAttributes.isBroadbandAttributeCreated,
    isBroadbandAttributeSuccess:
      state.BroadbandAttributes.isBroadbandAttributeSuccess,
    error: state.BroadbandAttributes.error,
  }));

  const [broadbandAttribute, setBroadbandAttribute] = useState([]);

  useEffect(() => {
    if (broadbandAttributes && !isBroadbandAttributeSuccess) {
      dispatch(onGetBroadbandAttributes());
    }
  }, [dispatch, broadbandAttributes]);

  useEffect(() => {
    setBroadbandAttribute(broadbandAttributes);
  }, [broadbandAttributes]);

  useEffect(() => {
    if (!isEmpty(broadbandAttributes)) {
      setBroadbandAttribute(broadbandAttributes);
    }
  }, [broadbandAttributes]);

  useEffect(() => {
    if (isBroadbandAttributeCreated) {
      setBroadbandAttribute(null);
      dispatch(onGetBroadbandAttributes());
    }
  }, [dispatch, isBroadbandAttributeCreated]);

  // BroadbandAttributes Column
  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        Cell: (product) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={product.row.original.imageUrl || ''}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <span className="text-dark">{product.row.original.name}</span>
                </h5>
                <p className="text-muted mb-0">
                  Type :
                  <span className="fw-medium">
                    {product.row.original.type.toUpperCase()}
                  </span>
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: 'Button Style',
        accessor: 'colorCode',
        filterable: false,
      },
      {
        Header: 'Tooltip',
        accessor: 'toolTip',
        filterable: false,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={'/broadband-attributes/edit/' + cellProps.row.original.id}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-pencil-fill fs-16"></i> Edit
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Broadband Attributes | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={broadbandAttributes}
        />

        <Container fluid>
          <BreadCrumb title="Broadband Attributes" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="BroadbandAttributeList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          Broadband Attributes List
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <Link
                          to="/broadband-attributes/add"
                          className="btn btn-success"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Broadband Attribute
                        </Link>
                        <button
                          type="button"
                          className="btn btn-info ms-1"
                          onClick={() => setIsExportCSV(true)}
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          Export
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isBroadbandAttributeSuccess ? (
                      <>
                        {broadbandAttributes.length ? (
                          <TableContainer
                            columns={columns}
                            data={broadbandAttributes || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            divClass="table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for BroadbandAttribute..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BroadbandAttributes;
