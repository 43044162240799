import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  ButtonGroup,
  Button,
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  addNewManageWidget as onAddNewManageWidget,
  updateManageWidget as onUpdateManageWidget,
  //getManageMenus as onGetManageMenus,
  getDomains as onGetDomains,
} from "../../slices/thunks";

import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// status
import StatusMenu from "../../Data/status.json";
import PreviewImage from '../../Components/Common/PreviewImage';
import axios from "axios";


const AddManageWidget = () => {
  const { manageWidgetid } = useParams();
  document.title = "Manage Widgets | Admin & Dashboard";

  const history = useNavigate();
  const dispatch = useDispatch();

  const {
    manageWidgets,
    //manageMenus,
    //isManageMenuSuccess,
    domains,
    isDomainSuccess,
  } = useSelector((state) => ({
    manageWidgets: state.ManageWidgets.manageWidgets,
    //manageMenus: state.ManageMenus.manageMenus,
    //isManageMenuSuccess: state.ManageMenus.isManageMenuSuccess,
    domains: state.Domains.domains,
    isDomainSuccess: state.Domains.isDomainSuccess,
  }));

  const [manageWidget, setManageWidget] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [city, setCity] = useState([]);
  const [providers, setProviders] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [subPage, setSubPage] = useState([]);
  const [manageDomain, setManageDomain] = useState([]);
  const [rSelected, setRSelected] = useState(null); // 1 = City , 2 = Provider
  const [showROption, setShowROption] = useState(false);


  const uploadToCloudinary = async (file) => {
    const img_file = file,
      formData = new FormData();
    formData.append('file', img_file);
    formData.append('upload_preset', 'broadband');
    const uninterceptedAxiosInstance = axios.create();
    let res = await uninterceptedAxiosInstance.post(
      'https://api.cloudinary.com/v1_1/dxzr7l0pe/image/upload',
      formData
    );
    return res.data.secure_url;
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rSelected: rSelected,
      name: (manageWidget && manageWidget.name) || "",
      title: (manageWidget && manageWidget.title) || "",
      description: (manageWidget && manageWidget.description) || "",
      freetext: (manageWidget && manageWidget.freetext) || "",
      status: (manageWidget && manageWidget.status) || "",
      countryId: (manageWidget && manageWidget.countryId) || "",
      cityId: (manageWidget && manageWidget.cityId) || "",
      providerId: (manageWidget && manageWidget.providerId) || "",
      sortingOrder: (manageWidget && manageWidget.sortingOrder) || "",
      image:(manageWidget && manageWidget.image) || "",
    },
    validationSchema: Yup.object({
      rSelected: Yup.number(),
      name: Yup.string().required("Please enter a widget short name"),
      title: Yup.string().required("Please enter a widget title heading"),
      status: Yup.string().required("Please select widget status"),
      countryId: Yup.string().required("Please select country"),
      cityId: Yup.string().when(
        "rSelected", {
          is: 1,
          then: (schema) => schema.required("Please select city")
      }),
      providerId: Yup.string().when(
        "rSelected", {
          is: 2,
          then: (schema) => schema.required("Please select provider")
        }
      ),
    }),
    onSubmit: async (values) => {
      let imageUrl = values.image;
      if (
        typeof imageUrl?.name != 'undefined' &&
        typeof imageUrl?.name == 'string'
      ) {
        imageUrl = await uploadToCloudinary(imageUrl);
      }


      if (isEdit) {
        const updateManageWidget = {
          id: manageWidget ? manageWidget.id : manageWidgetid,
          name: values.name,
          title: values.title,
          description: values.description,
          freetext: values.freetext,
          status: values.status,
          image: imageUrl,
          //countryId: parseInt(values.countryId),
          cityId: parseInt(values.cityId),
          sortingOrder: values.sortingOrder,
          projectId: 3,
          entityId: rSelected == 1 ? parseInt(values.cityId) : parseInt(values.providerId),
          entityName: rSelected == 1 ? "city" : "provider",
          siteId:
            parseInt(values.countryId) || sessionStorage.getItem("siteid"),
        };
        // update ManageWidget
        dispatch(onUpdateManageWidget(updateManageWidget));
        history("/manage-widgets");
        validation.resetForm();
      } else {
        const newManageWidget = {
          name: values["name"],
          title: values["title"],
          description: values["description"],
          freetext: values["freetext"],
          status: values["status"],
          //countryId: parseInt(values["countryId"]),
          entityId: rSelected == 1 ? parseInt(values.cityId) : parseInt(values.providerId),
          entityName: rSelected == 1 ? "city" : "provider",
          sortingOrder: values["sortingOrder"],
          image: imageUrl,
          projectId: 3,
          siteId: parseInt(values["countryId"]),
        };

        // save new manageWidget
        dispatch(onAddNewManageWidget(newManageWidget));
        history("/manage-widgets");
        validation.resetForm();
      }
    },
  });

  const selectPageOptions = async (countryId) => {
    if (countryId == "") {
      setSubPage([]);
      setShowROption(false);
    } else {
      setShowROption(true);
      
    }
  };

  const selectPage = (event) => {
    let countryId = event.target.value;
    if (countryId) {
      validation.setFieldValue("countryId", countryId);
      selectPageOptions(countryId);
      setCountryId(countryId)
      console.log("===================================================================",countryId);
    }
  };

  const getCityList = async (countryId) => {
    
    // if city selected
    if(rSelected == 1) {
      try {
        let response = await axios.get(
          `https://www.parislondrestrain.fr/admin/city/list`,
          {
            headers: {
              siteid: `${countryId}`,
              projectid: "3",
            },
          }
        );
  
        if (response.payload) {
          setCity(response.payload);
        }
      } catch (error) {}
  
      setSubPage([{ id: 0, name: "Main Page" }]);
    }

    //if provider selected
    if(rSelected == 2) {
      try {
        let response = await axios.get(
          `https://www.parislondrestrain.fr/admin/provider/list`,
          {
            headers: {
              siteid: `${countryId}`,
              projectid: "3",
            },
          }
        );
  
        if (response.payload) {
          setProviders(response.payload);
        }
      } catch (error) {}
  
      setSubPage([{ id: 0, name: "Main Page" }]);
    }
  }

  useEffect(() => {
    if (typeof manageWidgetid != "undefined" && !!manageWidgetid) {
      const fetchedElem = manageWidgets.find(
        (elem) => elem.id == manageWidgetid
      );
      if (typeof fetchedElem != "undefined") {
        setManageWidget({
          id: fetchedElem.id,
          name: fetchedElem.name || "",
          title: fetchedElem.title || "",
          description: fetchedElem.description || "",
          freetext: fetchedElem.freetext || "",
          image: fetchedElem.image || '',
          status: fetchedElem.status?.toString() || "",
          sortingOrder: fetchedElem.sortingOrder || "",
          countryId: fetchedElem.countryId?.toString() || "",
          cityId: fetchedElem.cityId?.toString() || "",
        });
        selectPageOptions(fetchedElem.countryId?.toString() || "");
        setIsEdit(true);
      } else {
        history("/manage-widgets");
      }
    }
  }, [manageWidgetid]);

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    if (sessionStorage.getItem("siteid")) {
      const filteredDomain = domains.filter(
        (item) => item.id == sessionStorage.getItem("siteid")
      );
      setManageDomain(filteredDomain);
    }
  }, [domains]);

  useEffect(() => {
    if(rSelected == 1) {
      setProviders([]);
      getCityList(countryId);
    }

    if(rSelected == 2) {
      setCity([]);
      getCityList(countryId);
    }
  }, [rSelected])

  const editorHandler = (event, editor) => {
    validation.setFieldValue("description", editor.getData());
  };

  console.log("providers", providers)

  const imageHandler = (event) => {
    validation.setFieldValue('image', event.target.files[0]);
    console.log("kkkkkkkkkkkkkkkkk",event);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={!!isEdit ? "Update Widget" : "Add Widget"}
          pageTitle="Dashboard"
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="manageWidget-title-input"
                        >
                          Widget ShortName for identification purpose
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="manageWidget-title-input"
                          placeholder="Enter Widget name"
                          name="name"
                          onChange={validation.handleChange}
                          value={validation.values.name || ""}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.errors.name && validation.touched.name
                              ? true
                              : false
                          }
                        />
                        {validation.errors.name && validation.touched.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="manageWidget-title-input"
                        >
                          Widget Title / Heading
                        </Label>
                        <div className="input-group">
                          <Input
                            type="text"
                            className="form-control"
                            id="manageWidget-title-input"
                            aria-describedby="manageWidget-addon"
                            placeholder="Enter widget heading title here"
                            name="title"
                            value={validation.values.title || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.title &&
                              validation.touched.title
                                ? true
                                : false
                            }
                          />
                          {validation.errors.title &&
                          validation.touched.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="description-input"
                        >
                          Description
                        </Label>
                        <CKEditor
                          editor={Editor}
                          data={validation.values.description || ""}
                          onChange={editorHandler}
                        />
                        {/* <Input
                          type="textarea"
                          className="form-control"
                          id="description-input"
                          placeholder="Enter description"
                          name="description"
                          rows="5"
                          value={validation.values.description || ""}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.description &&
                            validation.touched.description
                              ? true
                              : false
                          }
                        ></Input>
                        {validation.errors.description &&
                        validation.touched.description ? (
                          <FormFeedback type="invalid">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="freetext-input">
                          Sub Description (optional)
                        </Label>
                        <Input
                          type="textarea"
                          className="form-control"
                          id="freetext-input"
                          placeholder="Enter sub description"
                          name="freetext"
                          rows="5"
                          value={validation.values.freetext || ""}
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          invalid={
                            validation.errors.freetext &&
                            validation.touched.freetext
                              ? true
                              : false
                          }
                        ></Input>
                        {validation.errors.freetext &&
                        validation.touched.freetext ? (
                          <FormFeedback type="invalid">
                            {validation.errors.freetext}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end mb-3">
                <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button>
              </div>
            </Col>
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Miscellenaeous</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="sortingOrder-field" className="form-label">
                      Sorting Order
                    </Label>

                    <Input
                      name="sortingOrder"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="sortingOrder-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sortingOrder || ""}
                      invalid={
                        validation.errors.sortingOrder &&
                        validation.touched.sortingOrder
                          ? true
                          : false
                      }
                    />
                    {validation.touched.sortingOrder &&
                    validation.errors.sortingOrder ? (
                      <FormFeedback type="invalid">
                        {validation.errors.sortingOrder}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="status-field" className="form-label">
                      Status
                    </Label>

                    <Input
                      name="status"
                      type="select"
                      className="form-select"
                      id="status-field"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status || ""}
                      invalid={
                        validation.errors.status && validation.touched.status
                          ? true
                          : false
                      }
                    >
                      {StatusMenu.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Input>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>

                  {/* Manage Domain Change */}
                  {isDomainSuccess && manageDomain.length ? (
                    <div className="mb-3">
                      <Label htmlFor="menu-field" className="form-label">
                        Select Domain
                      </Label>

                      <Input
                        name="countryId"
                        type="select"
                        className="form-select"
                        id="countryId-field"
                        onChange={selectPage}
                        onBlur={validation.handleBlur}
                        value={validation.values.countryId || ""}
                        invalid={
                          validation.errors.countryId &&
                          validation.touched.countryId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {manageDomain.map((item, key) => {
                          return (
                            <option value={item.id} key={key}>
                              {item.websiteName || item.domainName}
                            </option>
                          );
                        })}
                      </Input>
                      {validation.touched.countryId &&
                      validation.errors.countryId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.countryId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Once Country Selected Option will show */}
                  {showROption ? (
                    <div className="mb-3">
                    <Label htmlFor="menu-field" className="form-label">
                      Choose One Option
                    </Label>
                    <div>
                      <ButtonGroup>
                        <Button
                          color=""
                          outline
                          onClick={() => setRSelected(1)}
                          active={rSelected === 1}
                        >
                          City
                        </Button>
                        <Button
                          color=""
                          outline
                          onClick={() => setRSelected(2)}
                          active={rSelected === 2}
                        >
                          Provider
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  ) : null}
                  
                  {rSelected && subPage.length && city.length ? (
                    <div className="mb-3">
                      <Label htmlFor="cityId-field" className="form-label">
                        Select City
                      </Label>
                      <Input
                        name="cityId"
                        type="select"
                        className="form-select"
                        id="cityId-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cityId || ""}
                        invalid={
                          validation.errors.cityId && validation.touched.cityId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                       
                        <option value="0">Country Page</option>
                        {city.length ? (
                          <option value="-1">All City Page</option>
                        ) : null}
                        {city.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.cityId && validation.errors.cityId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.cityId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}

                  {rSelected && subPage.length && providers.length ? (
                    <div className="mb-3">
                      <Label htmlFor="providerId-field" className="form-label">
                        Select Provider
                      </Label>
                      <Input
                        name="providerId"
                        type="select"
                        className="form-select"
                        id="providerId-field"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.providerId || ""}
                        invalid={
                          validation.errors.providerId && validation.touched.providerId
                            ? true
                            : false
                        }
                      >
                        <option value="">Select</option>
                        {providers.map((item, key) => (
                          <option value={item.id} key={key}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.providerId && validation.errors.providerId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.providerId}
                        </FormFeedback>
                      ) : null}
                    </div>
                  ) : ""}
                  <div className="mb-4">
                          <h5 className="fs-15 mb-1">Banner Image</h5>
                          <p className="text-muted">
                            800 X 500 preferable dimensions
                          </p>
                          <div>
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="product-image-input"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Select Image"
                                  data-bs-original-title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  id="product-image-input"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={imageHandler}
                                />
                              </div>
                              <div className="plan-main-img">
                                {validation.values.image &&
                                validation.values.image.name ? (
                                  <PreviewImage
                                    file={validation.values.image}
                                  />
                                ) : (
                                  <div className="avatar-title bg-light rounded">
                                    <img
                                      src={validation.values.image || ''}
                                      alt=""
                                      className="h-auto"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {validation.errors.image &&
                          validation.touched.image ? (
                            <FormFeedback type="invalid">
                              {validation.errors.image}
                            </FormFeedback>
                          ) : null}
                        </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default AddManageWidget;