import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {
  getDomains as onGetDomains,
  addNewDomain as onAddNewDomain,
  updateDomain as onUpdateDomain,
} from '../../slices/thunks';

//redux
import { useSelector, useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';

const Domains = () => {
  const dispatch = useDispatch();

  const { domains, isDomainCreated, isDomainSuccess, error } = useSelector(
    (state) => ({
      domains: state.Domains.domains,
      isDomainCreated: state.Domains.isDomainCreated,
      isDomainSuccess: state.Domains.isDomainSuccess,
      error: state.Domains.error,
    })
  );

  const [isEdit, setIsEdit] = useState(false);
  const [domain, setDomain] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDomain(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      domainName: (domain && domain.domainName) || '',
      websiteName: (domain && domain.websiteName) || '',
    },
    validationSchema: Yup.object({
      domainName: Yup.string().required('Please enter domain name'),
      websiteName: Yup.string().required('Enter Website name'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateDomain = {
          id: domain ? domain.id : 0,
          domainName: values.domainName,
          websiteName: values.websiteName,
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // update domain
        dispatch(onUpdateDomain(updateDomain));
        validation.resetForm();
      } else {
        const newDomain = {
          domainName: values['domainName'],
          websiteName: values['websiteName'],
          projectId: 3,
          siteId: sessionStorage.getItem('siteid'),
        };
        // save new domain
        dispatch(onAddNewDomain(newDomain));
        validation.resetForm();
      }
      toggle();
    },
  });
  // Update Data
  const handleDomainClick = useCallback(
    (arg) => {
      const domain = arg;

      setDomain({
        id: domain.id,
        domainName: domain.domainName,
        websiteName: domain.websiteName,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  useEffect(() => {
    if (domains && !isDomainSuccess) {
      dispatch(onGetDomains());
    }
  }, [dispatch, domains]);

  useEffect(() => {
    setDomain(domains);
  }, [domains]);

  useEffect(() => {
    if (!isEmpty(domains)) {
      setDomain(domains);
      setIsEdit(false);
    }
  }, [domains]);

  // Add Data
  const handleDomainClicks = () => {
    setDomain('');
    setIsEdit(false);
    toggle();
  };

  useEffect(() => {
    if (isDomainCreated) {
      setDomain(null);
      dispatch(onGetDomains());
    }
  }, [dispatch, isDomainCreated]);

  // Domains Column
  const columns = useMemo(
    () => [
      {
        Header: 'Site Id',
        accessor: 'id',
        Cell: (cell) => {
          return cell.value;
        },
      },
      {
        Header: 'Domain',
        accessor: 'domainName',
        filterable: false,
      },
      {
        Header: 'Website Name',
        accessor: 'websiteName',
        filterable: false,
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const domainData = cellProps.row.original;
                    handleDomainClick(domainData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i> Edit
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleDomainClick]
  );

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'Domains | Admin & Dashboard';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Domains" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="domainList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Domain List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Domain
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {isDomainSuccess ? (
                      <>
                        {domains.length ? (
                          <TableContainer
                            columns={columns}
                            data={domains || []}
                            isGlobalFilter={true}
                            isAddUserList={false}
                            customPageSize={10}
                            className="custom-header-css"
                            handleDomainClick={handleDomainClicks}
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search for domain..."
                          />
                        ) : (
                          <div className="py-4 mt-4 text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: '72px', height: '72px' }}
                            ></lord-icon>
                            <h5 className="mt-4">Sorry! No Result Found</h5>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? 'Edit Domain' : 'Add Domain'}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: 'none' }}
                        >
                          <Label htmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="domainname-field"
                            className="form-label"
                          >
                            Domain Name
                          </Label>
                          <Input
                            name="domainName"
                            id="domainname-field"
                            className="form-control"
                            placeholder="Enter Domain Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domainName || ''}
                            invalid={
                              validation.touched.domainName &&
                              validation.errors.domainName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.domainName &&
                          validation.errors.domainName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.domainName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="websiteName-field"
                            className="form-label"
                          >
                            Website Name
                          </Label>
                          <Input
                            name="websiteName"
                            id="websiteName-field"
                            className="form-control"
                            placeholder="Enter Website Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.websiteName || ''}
                            invalid={
                              validation.touched.websiteName &&
                              validation.errors.websiteName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.websiteName &&
                          validation.errors.websiteName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.websiteName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {!!isEdit ? 'Update' : 'Add Domain'}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Domains;
