import { createSlice } from '@reduxjs/toolkit';
import {
  getManageWidgetCards,
  addNewManageWidgetCard,
  updateManageWidgetCard,
} from './thunk';
export const initialState = {
  manageWidgetCards: [],
  error: {},
};

const ManageWidgetCardsSlice = createSlice({
  name: 'ManageWidgetCardsSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getManageWidgetCards.fulfilled, (state, action) => {
      state.manageWidgetCards = action.payload.payload;
      state.isManageWidgetCardCreated = false;
      state.isManageWidgetCardSuccess = true;
    });

    builder.addCase(getManageWidgetCards.rejected, (state, action) => {
      state.error = action.error.message || null;
      state.isManageWidgetCardCreated = false;
      state.isManageWidgetCardSuccess = false;
    });

    builder.addCase(addNewManageWidgetCard.fulfilled, (state, action) => {
      state.manageWidgetCards.push(action.payload.payload);
      state.isManageWidgetCardCreated = true;
    });
    builder.addCase(addNewManageWidgetCard.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addCase(updateManageWidgetCard.fulfilled, (state, action) => {
      state.manageWidgetCards = state.manageWidgetCards.map(
        (manageWidgetCard) =>
          manageWidgetCard.id.toString() ===
          action.payload.payload.id.toString()
            ? { ...manageWidgetCard, ...action.payload.payload }
            : manageWidgetCard
      );
    });
    builder.addCase(updateManageWidgetCard.rejected, (state, action) => {
      state.error = action.error.message || null;
    });
  },
});

export default ManageWidgetCardsSlice.reducer;